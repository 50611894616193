import Carousel from 'react-bootstrap/Carousel';

import HeaderImage2 from '../assets/img/HeaderImage/HeaderImage2.png'
import HeaderImage3 from '../assets/img/HeaderImage/HeaderImage3.png'
import HeaderImage4 from '../assets/img/HeaderImage/HeaderImage4.png'

function Header() {
    return (
        <Carousel slide={false}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={HeaderImage2}
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={HeaderImage3}
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={HeaderImage4}
                    alt="Third slide"
                />
            </Carousel.Item>
        </Carousel>
    );
}

export default Header;