import React from "react";
import { Helmet } from "react-helmet";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import Banner38 from '../assets/img/pagesBanner/banner38.png';
import Banner39 from '../assets/img/pagesBanner/banner39.png';

import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function ConservatoryCourseScreen() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="İstanbul Pendik Kurtköyün en kapsamlı ve en tecrübeli müzik okulu. Black Music Fox Sanat Okulu." />
                <meta name="keywords" content="
                konservatuara hazırlık kursu,
                konservatuara hazırlık dersi,
                kurtköy konservatura hazırlık kursu,
                kurtköy konservatura hazırlık dersi,
                pendik konservatuara hazırlık dersi,
                pendik konservatuara hazırlık kursu,
                istanbul konservatura hazırlık dersi,
                istanbul konservatura hazırlık kursu,
                güzel sanatlara hazırlık kursu,
                güzel sanatlara hazırlık dersi,
                kurtköy güzel sanatlara hazırlık dersi,
                kurtköy güzel sanatlara hazırlık kursu,
                pendik güzel sanatlara hazırlık dersi,
                pendik güzel sanatlara hazırlık kursu,
                istanbul güzel sanatlara hazırlık dersi,
                istanbul güzel sanatlara hazırlık kursu,
                anadolu yakası güzel sanatlara hazılık kursu,
                anadolu yakası güzel sanatlara hazılık dersi,
                anadolu yakası konservatura hazırlık dersi,
                anadolu yakası konservatura hazırlık kursu,
                "></meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Konservatuara ve Güzel Sanatlara Hazırlık</title>
            </Helmet>
            <TopNavbar />
            <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner38}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner39}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Konservatuara ve Güzel Sanatlara Hazırlık</h1>
                        <br></br>
                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>
                        <ul>
                            <li>Konservatuara ve Güzel Sanatlara Hazırlık  dersi; konusunda uzman hocalarımızla, bire bir yapılmaktadır.</li>
                            <li>Ders günü ve saatlerini hocalarımızla öğrenciler, uygunluk dahilinde karşılıklı belirleyebilmektedir.</li>
                            <li>Konservatuara hazırlık dersleri kişiye özel olarak uygulanır.</li>
                            <li>Çocuklara konservatuara hazırlık eğitimi vermekteyiz ve bireylerin ders için başlama yaşı en az yedidir.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <blockquote>
                            Müzik eğitimi, beynimizin analitik, bilişsel ve duygusal gelişiminde çok önemli rol oynar.       Bu kazanım bireyin toplumsal ve bireysel olarak kendini ifade edebilmesinin en önemli parçasıdır. Bu alanda yıllar boyunca süre gelen araştırmalar, enstrüman öğrenmenin bir yaşı olmadığı yönündeki fikirleri kanıtlar niteliktedir. Özellikle çocuk ve genç yaşta müzik ile ilgilenmeye başlayan bireylerin; özgüven, kendini ifade edebilme, fiziksel, entellektüel ve sosyal gelişim alanlarında yaşıtlarına göre daha ileri seviyelerde olduklarını görmekteyiz.
                            İşte bu bilgiler ışığında Black Music Fox Kursu müzik alanında uzman, donanımlı, tecrübeli eğitmen kadrosu ve hedefe yönelik eğitim metodları ile sizleri ;
                        </blockquote>
                        <br />
                        <ul>
                            <li>Güzel Sanatlar Liseleri</li>
                            <li>Müzik Öğretmenliği Fakülteleri</li>
                            <li>Güzel Sanatlar Fakülteleri</li>
                            <li>Konservatuarlar sınavlarına hazırlamaktadır.</li>
                        </ul>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}