import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css';

import EnstrumentFixing1 from '../assets/img/EnstrumentFixing/EnstrumentFixing1.png'
import EnstrumentFixing2 from '../assets/img/EnstrumentFixing/EnstrumentFixing2.png'
import EnstrumentFixing3 from '../assets/img/EnstrumentFixing/EnstrumentFixing3.png'
import EnstrumentFixing4 from '../assets/img/EnstrumentFixing/EnstrumentFixing4.png'
import EnstrumentFixing5 from '../assets/img/EnstrumentFixing/EnstrumentFixing5.png'

function EnstrumentFixingInfo() {
    return (
        <>
            <Container className='mt-5'>
                <Card>
                    <Card.Header>Elektro Gitar Tamiri</Card.Header>
                    <Card.Img variant="top" src={EnstrumentFixing1} />
                    <Card.Body>
                        <Card.Text>
                            Elektro Gitar Tamiri | Elektro Gitar Bakımı | Elektro Gitar Eğitimi için ekibimizden destek alabilirsiniz.
                        </Card.Text>
                        <Button href='/telli-calgilar' variant="warning">Daha Fazla Bilgi Alın</Button>
                    </Card.Body>
                </Card>
                <br></br>
                <Row xs={1} md={2} className="g-4">
                    <Col>
                        <Card>
                            <Card.Header>Klasik Gitar Tamiri</Card.Header>
                            <Card.Img variant="top" src={EnstrumentFixing2} />
                            <Card.Body>
                                <Card.Text>
                                    Klasik Gitar Tamiri | Klasik Gitar Bakımı | Klasik Gitar Eğitimi için ekibimizden destek alabilirsiniz.
                                </Card.Text>
                                <Button href='/telli-calgilar' variant="warning">Daha Fazla Bilgi Alın</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>Akustik Gitar Tamiri</Card.Header>
                            <Card.Img variant="top" src={EnstrumentFixing3} />
                            <Card.Body>
                                <Card.Text>
                                    Akustik Gitar Tamiri | Akustik Gitar Bakımı | Akustik Gitar Eğitimi için ekibimizden destek alabilirsiniz.
                                </Card.Text>
                                <Button href='/telli-calgilar' variant="warning">Daha Fazla Bilgi Alın</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br></br>
                <Row xs={1} md={2} className="g-4">
                    <Col>
                        <Card>
                            <Card.Header>Bağlama Tamiri</Card.Header>
                            <Card.Img variant="top" src={EnstrumentFixing4} />
                            <Card.Body>
                                <Card.Text>
                                    Bağlama Tamiri | Bağlama Bakımı | Bağlama Eğitimi için ekibimizden destek alabilirsiniz.
                                </Card.Text>
                                <Button href='/telli-calgilar' variant="warning">Daha Fazla Bilgi Alın</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Header>Keman Tamiri</Card.Header>
                            <Card.Img variant="top" src={EnstrumentFixing5} />
                            <Card.Body>
                                <Card.Text>
                                    Keman Tamiri | Keman Bakımı | Keman Eğitimi için ekibimizden destek alabilirsiniz.
                                </Card.Text>
                                <br></br>
                                <Button href='/yayli-calgilar' variant="warning">Daha Fazla Bilgi Alın</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <br />
        </>
    );
}

export default EnstrumentFixingInfo;