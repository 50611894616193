import React from "react";

import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import banner36 from '../assets/img/pagesBanner/banner36.png';
import banner37 from '../assets/img/pagesBanner/banner37.png';
import banner48 from '../assets/img/pagesBanner/banner48.png';

import { Helmet } from "react-helmet";

import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function PianoCourseScreen() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="İstanbul Pendik Kurtköyün en kapsamlı ve en tecrübeli müzik okulu. Black Music Fox Sanat Okulu." />
                <meta name="keywords" content="
                piyano dersi,
                piyano kursu,
                kurtköy piyano kursu,
                pendik piyano kursu,
                piyano kursu fiyatları,
                piyano dersleri,
                online piyano dersi,
                piyano dersi fiyatları,
                piyano eğitimi,
                piyano kursları,
                özel piyano dersi,
                piyano özel ders,
                çocuklar için piyano dersi,
                kurtköy piyano dersi,
                piyano nasıl öğrenirim,
                istanbul piyano dersi,
                istanbul piyano kursu,
                anadolu yakası piyano kursu,
                anadolu yakası piyano dersi,
                "></meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Piyano Kursu</title>
            </Helmet>
            <TopNavbar />
                <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner36}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner37}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner48}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Piyano Kursumuz</h1>
                        <br></br>
                        <p>Piyano  Çalmayı</p>
                        <p>En eğlenceli method ile</p>
                        <p>En uygun fiyata</p>
                        <p>Kolay şekilde öğrenmek istiyorsanız</p>

                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>

                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Piyano Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Piyano Dersi haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Piyano Kursu için Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Piyano Dersleri aynı anda teorik olarak nota-solfej eğitimi ve piyano ile uygulamalı verilmektedir.</li>
                            <li>Piyano kursumuza katıan öğrencilerimiz  piyano dersliğimizin  müsait olan saatlerinde ÜCRETSİZ PRATİK yapabilirler.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <h5 style={{ color: 'red' }}>PİYANO HAKKINDA BİLİNMESİ GEREKENLER</h5>
                        <blockquote>
                            Piyano, İtalya'da Bartolomeo Cristofori tarafından 1700 yılı civarında icat edilmiş (tam olarak yıl belirsizdir) akustik, telli bir müzik aletidir. Piyanoda ses, teller vasıtasıyla elde edilir. Piyanonun tuşlarına basıldığında içindeki tahta çekiç tellere vurarak sesi oluşturur. Tahta çekicin tellere vurmasından dolayı piyano bazen vurmalı telli çalgı olarak da sınıflandırılır. Piyano klasik ve caz müzikte yaygın olarak kullanılır. Solo performanslar, ansambl, oda müziği, eşlik, bestecilik ve prova için oldukça uygun bir enstrümandır. Piyano taşınabilir bir enstrüman olmamasına ve genelde pahalı olmasına rağmen çok yönlülüğü ve aynı anda birçok yerde bulunma özelliği ile dünyanın en yaygın olarak kullanılan enstrümanlarından biridir.
                        </blockquote>
                        <br />
                        <blockquote>
                            Akustik piyanolar genellikle ses tahtasını ve metal telleri çevreleyen aynı zamanda koruyan ahşap kasadan oluşmakla birlikte 88 tuşa (52 beyaz tuş, 36 siyah tuş) sahiptir. Piyano, tuşlarına basıldığında içerisindeki teller aracılığıyla ses çıkarır, tuş bırakıldığında ise teller damper (titreşim azaltan parça) yoluyla susturulur. Fakat pedallar yardımıyla tuşlardan parmaklar kaldırmasına rağmen sesi uzatmak mümkündür.
                        </blockquote>
                        <h5 style={{ color: 'red' }}>PİYANO ÇALIŞMA STİLİ</h5>
                        <blockquote>
                            Piyanoda herhangi bir tuşa bastığımızda keçe ile kaplanmış çekiç o tuşa ait tellere vurur, ardından geri gelir ve çekiç eski konumuna gelmesine rağmen teller titreşmeye devam eder. Bu titreşme bridge (köprü) yoluyla ses tahtasına iletilir ve ses tahtası sesi yükselttikten sonra havaya yayar. Parmak tuştan çekildiğinde damper (titreşim azaltan parça) tellerin titreşmesini durdurur ve sesi keser. Yukarıda bahsedildiği gibi akustik piyano içerisinde bolca tel bulundurmasına rağmen vurmalı çalgı olarak sınıflandırılır çünkü teller çekme yoluyla (harpsikord ya da epinet) gibi değil vuruş yoluyla ses çıkartır. Hornbostel-Sachs enstrüman sınıflandırma sistemine göre piyano chordophone (telleri ve onu ileten gövdeleri olan enstrümanlar) olarak sınıflandırılmıştır. Örneğin arp telleri çekme yoluyla çalınan, gitar telleri tıngırdatma yoluyla çalınan, keman yayı (arşe) tellere sürtme yoluyla çalınan, piyano da tellere çekiç ile vurulması yoluyla çalınan chordophone bir enstrümandır. Teknolojik gelişmelerle birlikte chordophone bir enstrüman olan piyano akustik olmasının yanı sıra elektrikli, elektronik ve dijital olacak şekillerde de geliştirilmiştir.
                        </blockquote>
                        <br />
                        <blockquote>
                            “Piyano” sözcüğü, çalgının 1700’lü yıllarda kullanılan eski versiyonlarının İtalyanca adı olan “pianoforte” sözcüğünün kısaltılmış hâlidir.
                        </blockquote>

                        <span>İşlevine Göre Piyano Çeşitleri</span>
                        <ul>
                            <li>Duvar (Konsol) Piyanosu.</li>
                            <li>Elektronik (Dijital) Piyano.</li>
                            <li>Kuyruklu Piyano.</li>
                            <li>Pnömatik Piyano.</li>
                            <li>Mekanik Piyano.</li>
                            <li>Hazırlanmış (Prepared) Piyano.</li>
                        </ul>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}