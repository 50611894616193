import React from 'react'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import example from '../assets/img/pagesBanner/example.png'
import WhatsAppContact from '../components/WhatsAppContact';

import yanflut from '../assets/img/flut-icon.png'
export const WindInstrumentsScreen = () => {
    return (
        <>

            <TopNavbar />
            <br />
            <WhatsAppContact />
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>

                <br />

                <Row>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Klarnet Tamiri</h2>
                        <img src="https://img.icons8.com/fluency/96/null/clarinet.png" alt='klarnet tamiri'/>
                        <a role="button" tabindex="0" href="/klarnet-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Yan Flüt Tamiri</h2>
                        <img src={yanflut} alt='yan flüt tamiri'/>
                        <a role="button" tabindex="0" href="/yan-flut-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Saksafon Tamiri</h2>
                        <img src="https://img.icons8.com/color/96/null/alto-saxophone.png" alt='saksafon tamiri'/>
                        <a role="button" tabindex="0" href="/saksafon-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                </Row>
                <br />
            </Container>
            <br />
            <Footer />
        </>
    )
}
