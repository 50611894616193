import React from 'react'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import example from '../assets/img/pagesBanner/example.png'
import baglama from '../assets/img/baglama.png'
import WhatsAppContact from '../components/WhatsAppContact';
export const StringedInstrumentsScreen = () => {
    return (
        <>

            <TopNavbar />
            <br />
            <WhatsAppContact />
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>

                <br />

                <Row>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Klasik Gitar Tamiri</h2>
                        <img src="https://img.icons8.com/color/96/000000/guitar.png" alt="klasik gitar tamiri" ></img>
                        <a role="button" tabindex="0" href="/klasik-gitar-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Akustik Gitar Tamiri</h2>
                        <img src="https://img.icons8.com/stickers/96/000000/guitar.png" alt="akustik gitar tamiri"></img>
                        <a role="button" tabindex="0" href="/akustik-gitar-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Elektro Gitar Tamiri</h2>
                        <img src="https://img.icons8.com/color/96/000000/rock-music.png" alt="elektro gitar tamiri"></img>
                        <a role="button" tabindex="0" href="/elektro-gitar-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>

                </Row>
                <br />
                <Row>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Bas Gitar Tamiri</h2>
                        <img src="https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/96/null/external-guitar-music-kiranshastry-lineal-color-kiranshastry.png" alt='bas gitar tamiri'></img>
                        <a role="button" tabindex="0" href="/bas-gitar-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Bağlama Tamiri</h2>
                        <img src={baglama} alt="baglama tamiri"></img>
                        <a role="button" tabindex="0" href="/baglama-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Ud Tamiri</h2>
                        <img src="https://img.icons8.com/color/96/null/middle-east-music.png" alt='ud tamiri'/>
                        <a role="button" tabindex="0" href="/ud-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                </Row>
            </Container>
            <br />
            <Footer />
        </>
    )
}
