import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner11 from '../assets/img/pagesBanner/banner11.png'
import banner84 from '../assets/img/pagesBanner/banner84.png'
import banner85 from '../assets/img/pagesBanner/banner85.png'

export const YanFlutFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner11}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner84}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner85}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Yan Flüt Tamiri</h2>
                        <blockquote>
                            Firmamız, küçük ayarlamalardan revizyonlara, mekanik yükseltmeler, iyileştirmeler ve bakıma kadar şirket içi, profesyonel kalitede flüt hizmetleri sunmaktadır. Teknisyenlerimizin tamamı flütçüdür ve özellikle flüt ve pikolo onarımı ve bakımı konusunda eğitilmiştir. İster tanınmış bir sanatçıya, ister yerel tutkulu bir öğrenciye ait olsun, dünyanın en değerli enstrümanlarından bazıları üzerinde çalışma yapacağımıza 8 yıldan fazla süredir güveniliyoruz, her biri bizim ayrıntılara ve kaliteye son derece saygı duyulan dikkatimizi alıyor. Tüm büyük üreticiler tarafından onaylanmış, sattığımız tüm markalar için de garanti hizmeti sağlayabiliriz.
                            Cihazınızı bize göndermeden önce, lütfen mevcut açıklıklar için bizi arayın.


                            Onarımlar, bir anahtar yüksekliğini ayarlamak kadar basit veya bir kutu eklemini veya ton deliğini yeniden lehimlemek kadar karmaşık olabilir. Cihazınızda bir sorun olduğuna inanıyorsanız, lütfen bizimle iletişime geçin. Onarım tahminlerimiz her zaman ücretsizdir. Aşağıda sunduğumuz bazı genel onarım hizmetleri verilmiştir.
                        </blockquote>

                        <ul>
                            <li>Anahtar ayarı</li>
                            <li>Mekanizma yeniden hizalama</li>
                            <li>Göçük ve çizik giderme</li>
                            <li>Ped, mantar ve keçe değişimi</li>
                            <li>Straubinger Sertifikalı balata onarımı ve değişimi</li>
                        </ul>
                    </Col>
                </Row>
                <br></br>
                <h4>BAKIM</h4>
                <blockquote>
                    Optimum çalma koşulunu elde etmek için tüm cihaz üreticileri tarafından düzenli koruyucu bakım önerilir. Bakımı iyi yapılmamış bir enstrüman yalnızca performansınızı engelleyebilir. Düzenli bakım genellikle "yıllık servis" veya "temiz, yağla ve ayarla (diğer adıyla COA)" olarak adlandırılır ve bunların tümü, cihazı iyi çalışır durumda tutmak için kalifiye bir onarım teknisyeni tarafından düzenli servisin eşanlamlısıdır. (arabanız için bir yağ ve filtre değişimi olarak düşünün). Üreticinin yönergeleri, enstrümanınızın başlangıç durumuna, ne kadar çalındığına, çalındığında ne kadar iyi korunduğuna ve diğer çevresel koşullara bağlı olarak yılda bir kez bu seviyede servis gerektireceğini önermektedir. Carolyn Nussbaum Music Company, dünya çapındaki flütçüler tarafından enstrümanlarında bu kritik hizmeti gerçekleştirmesi için her yıl güvenilmektedir. Carolyn Nussbaum Music Company tarafından gerçekleştirilen önleyici bakım, her enstrümanda aşağıdaki hizmetlerin TÜMÜNÜ içerir ve herhangi bir ek servisin gerekli olması veya önerilmesi durumunda enstrümanınızın durumuna ilişkin bir danışma eşlik edecektir. Bir bakım randevusu planlamak için bugün bizimle iletişime geçin.

                    •	tam demontaj
                    <br></br>
                    •	temiz ve elle cila hortumu ve mekanizması
                    <br></br>
                    •	yağlı odun (piccolos üzerinde)
                    <br></br>
                    •	baş eklemli mantar değiştirme
                    <br></br>
                    •	yağ mekanizması
                    <br></br>
                    •	tuşları ayarlayın ve mekanizmayı düzenleyin
                    <br></br>
                    •	tüm sızıntıları giderin
                    <br></br>
                    •	profesyonel flütçüler tarafından kalite güvencesi çalma testi
                    <br></br>
                </blockquote>
                <br></br>
                <h4>ÖZEL BAKIM</h4>
                <blockquote>
                    Genellikle daha ucuz " düzenli bakım ", " yıllık servis " veya " temizleme, yağlama ve ayarlama  olarak da bilinir) durumu ile karıştırılır" hizmetler, bir aletin tam olarak elden geçirilmesinin garanti edildiği zamanlar vardır. İyi yapılandırılmış, bakımlı bir alet, çevresel koşullara ve aletin miktarına bağlı olarak tam bir revizyon gerektirmeden önce 10-15 yıl veya daha uzun sürebilir. Firmamız, değerli enstrümanınızın bu tür bir revizyonunu gerçekleştirme becerisine ve yeteneğine ve bu hizmete olan ihtiyacınıza ilişkin profesyonel değerlendirmeler ve tavsiyeler sunma deneyimine sahibiz. Komple enstrüman revizyonu, aşağıdaki temel hizmetleri içerir ve flütünüzün veya pikolonuzun yeni olduğu zamanki kadar yakın veya daha iyi çalmasını sağlayın.


                    •	tam demontaj
                    <br></br>
                    •	temiz ve elle cila hortumu ve mekanizması
                    <br></br>
                    •	yağlı odun (piccolos üzerinde)
                    <br></br>
                    •	baş eklemli mantar değiştirme
                    <br></br>
                    •	yağ mekanizması
                    <br></br>
                    •	tuşları ayarlayın ve mekanizmayı düzenleyin
                    <br></br>
                    •	TÜM pedleri değiştirin
                    <br></br>
                    •	TÜM keçeleri ve mantarları değiştirin
                    <br></br>
                    •	ezik ve çizikleri giderin
                    <br></br>
                    •	profesyonel flütçüler tarafından kalite güvence çalma testi (uzun süre)
                    <br></br>
                </blockquote>
                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
