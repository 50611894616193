import React from "react";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useMediaQuery } from 'react-responsive'

export default function EnstrumentFixingText() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <br/>
            <Container>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Enstrüman Tamir Bakım ve Onarım</h1>
                        <br></br>
                        <blockquote>
                            Enstrümanlarınızın bakım ve tamiri ciddi bir iştir. Ülkemizde enstrüman tamiri alanında çalışan uzmanların sayısının azlığı da dikkate alındığında,
                            bu faaliyet için uygun bir kaynak bulmanız ve düzenli olarak bu kişi veya kuruluşla çalışmanız faydanıza olacaktır. Biz kurumumuzda enstrüman tamiri
                            hizmeti vermekteyiz ve bir çok saz için desteğimizi sunmaktayız. Özellikle düzenli bakım gerektiren enstrümanlarınız için uygun fiyatlarla desteği
                            bizden alabilirsiniz.
                            Enstrüman veya çalgı tamiri; ustalığın yanı sıra eğitim de gerektiren bir faaliyettir. Üniversitelerin çalgı yapımı bölümlerinde eğitim almış ve
                            alanında uzmanlaşmış lütiyelerin elinden enstrümanlarınızın tamir ve bakım hizmetini bizden alabilirsiniz. Unutulmamalıdır ki, bakım; en az enstrümanın
                            nitelikleri kadar önemlidir ve hizmet alacağınız kişilerin bu işlemleri gerektiği şekilde sunmaları son derece önemlidir.
                        </blockquote>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <h3>Enstrüman Tamiri Nasıl Yapılır?</h3>
                        <blockquote>
                            Elbette enstrüman tamiri; enstrümanın türüne ve arızanın niteliğine göre farklı şekillerde yapılır. Örneğin, bir gitarın sapı ile ilgili arıza ile
                            trompet mekanizmasının arıza giderme işlemleri aynı değildir. Hizmeti sunan lütiyenin bu iki alanda uzmanlığı olması koşuluyla iki farklı tip arıza
                            için farklı prosedürler söz konusudur.Bu noktada ustalık gerektiren prosedürlerin kullanılması elbette önemlidir ancak mutlaka doğru yedek parça ve
                            aracın kullanılması da gerekir. Bize enstrüman tamiri için gelen çalgının niteliklerine uygun prosedürü tercih eder, bunun yanı sıra çalgının
                            niteliklerine uygun yedek parça ve materyali de kullanırız. Böylece enstrüman tamiri uygulamaları sonrasında enstrümanınızın orijinalliği bozulmaz
                            ve niteliklerinde kayıp yaşanmaz.Hangi estrümanlar için tamir hizmeti alabilirsiniz?Bizden tüm çalgılar için enstrüman tamiri hizmeti alabilirsiniz.
                            Yalnızca spesifik enstrümanlar için hizmet vermeyebiliyoruz. Burada uzmanlığımız olmayan işi yaparak sizi mağdur etmeme misyonumuz gereğince, en uygun
                            protokolleri uygulamayı tercih ediyoruz.Yaylı enstrümanlar,Nefesli enstrümanlar Telli enstrümanlar,Tuşlu enstrümanlar,Vurmalı enstrümanlar,Etnik çalgılar
                            Gibi hemen her türde çalgı için enstrüman tamiri hizmetini bizden alabilirsiniz. Dilerseniz enstrümanınızı getirmeden önce bizimle iletişime geçerek,
                            size destek verip veremeyeceğimiz noktasında bilgi talebinde de bulunabilirsiniz.
                        </blockquote>

                        <br />
                        <h3>Enstrüman Bakımı Hizmetleri</h3>
                        <blockquote>
                            Enstrüman tamiri hizmetinin içerisinde, bakım hizmetleri de yer alır. Örneğin; piyano akordu, gitar entonasyon ve sap ayarı, nefesli çalgıların
                            temizlenmesi ve tel değişimi gibi hizmetler, sizlere sunduğumuz standart hizmetler arasında yer alır.Enstrüman bakımı; en az enstrüman tamiri kadar
                            önemli bir uygulama olarak kabul edilir. Bakımları zamanında ve gerektiği gibi yapılmayan çalgılar verimli biçimde kullanılamayacağı gibi; bu çalgıların
                            bir arızayla karşılaşma olasılıkları da oldukça fazladır. Bu nedenle bize ulaşmalı ve bakım hizmetlerini vakit kaybetmeden almalısınız.Kurumumuz enstrüman
                            tamiri hizmetlerini yüksek standartlarda ve mümkün olan en uygun fiyatlarla verir. Bize şimdi ulaşın, sizin de enstrüman bakımı ve tamiri ihtiyaçlarınızı
                            protokollere uygun biçimde ve en kısa zamanda karşılayalım.
                        </blockquote>
                        <br />
                        <h3>Misyonumuz</h3>
                        <blockquote>
                            Eski veya bozulmuş olan enstrümanlarınızı onarım sağlayarak yenilenmesini sağlıyoruz. Türkiye geneli müzik aletlerinizi kargo ile mağazamıza teslim
                            edebilirsiniz. Onarımı en kısa sürede yapılarak sizlere teslim edilecektir.
                            Atölye ekibimiz, eski cihazlarda sıklıkla gerekli olan kapsamlı onarımları yapıp yapmama veya başka bir cihaz satın almanın sizin için daha
                            iyi bir seçenek olup olmayacağına karar vermenize yardımcı olabilir.
                            Bazı durumlarda, bir alet onarım maliyetine değmeyebilir. Eski cihazınızla ilgili sorularınız varsa, randevu almak için lütfen bizi arayın.
                        </blockquote>
                        <br />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}