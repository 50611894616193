import React from 'react'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';

import Header from '../components/Header';
import EnstrumentFixingInfo from '../components/EnstrumentFixingInfo';
import GoogleMaps from '../components/GoogleMaps';
import {Contact} from '../components/Contact'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';

import '../assets/styles/contact.css'
import '../assets/styles/footer.css'
import EnstrumentFixingText from '../components/EnstrumentFixingText';

export default function HomeScreen() {
  return (

    <div>
      <TopNavbar />
      <Header />
      <EnstrumentFixingText/>
      <WhatsAppContact />
      <EnstrumentFixingInfo />
      <GoogleMaps />
      <Contact />
      <Footer />
    </div>

  );
}