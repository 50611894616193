import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner76 from '../assets/img/pagesBanner/banner76.png'
import banner84 from '../assets/img/pagesBanner/banner84.png'
import banner93 from '../assets/img/pagesBanner/banner93.png'

export const KlarnetFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner76}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner84}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner93}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Klarnet Tamiri</h2>
                        <blockquote>
                            <span className='text-danger'>GÜNLÜK BAKIM</span> : Günlük çalışma sonunda, çalının bek bölümü ve gövdesinin içerisindeki ağızdan çıkan buhar birikintileri ile, üzerindeki tozun silinip temizlenmesi ve eklem mantarlarının vazelinle yağlanmasıdır.
                        </blockquote>
                        <blockquote>
                            <span className='text-danger'>AYLIK BAKIM</span> : Günlük bakım işleminden sonra enstrümanın sökülerek temizlenip yağlanmasıdır. Bu bakımda icracı; reglajı bozulan veya kırılan yayları ve ayar ile bağlantısı olmayıp tek başına çalışan perde güderlerini değiştirebilir.
                        </blockquote>
                        <blockquote>
                            <span className='text-danger'>GENEL BAKIM</span>Günlük ve aylık bakımların dışında kalan işlemlerdir. Çalgı onarım teknisyenleri tarafından yapılır. Çalgının tüm olarak bakımıdır ki, güderi ve yayların değiştirilmesi, vida, mantar ve keçe ayarlarının yapılması gibi işlemleri kapsar. Bu çalgıları temizlemek için çalıcının, her kutuda bulunan yumuşak tüylü fırçayı kullanması gerekir. Bu çalgıların içini demir çubukla silmek kesin olarak yasaktır. Çalıcının yumuşak tüylü fırçayı, her zaman kutusunda bulundurması zorunludur. Eğer bu fırça yitirilmiş ise, yenisi gelene dek temizleme işlemi; bir ucuna mantar bağlanan bir ipin, diğer ucuna yumuşak bir bez parçası bağlanıp, ipin ağırlıklı ucu çalgının içerisine sarkıtılarak bezin çekilmesi biçiminde yapılır. Soluk birikintilerinin etkisiyle, güderilerin çabuk çürümesini önlemek için, bu temizliğin her çalışma sonunda aksatılmadan yapılması gerekir. Kamışlar her çalışma sonunda özenle silinmeli ve korunmalıdır. Bekler sabunlu ılık su içerisinde sık sık yıkanmalıdır.
                            Çalgı hiç bir zaman perdeleri üzerinde bırakılmaz.
                        </blockquote>
                    </Col>
                </Row>
                <br></br>
                <blockquote>
                    <span className='text-danger'>KLARNET ENSTRÜMANI DELİK TEMİZLİĞİ</span> : Klarnet enstrümanı delikleri belli bir icra sonrasında elde biriken toz ve kirin etkisi ile tıkanır ve deliğin tıkanması ile birlikte enstrüman da akord olarak bir kaç cent pesleşmeye sebebiyet verebilir. İşte bu sebeble her daim enstrüman deliklerinin orjinal kalması ve içinin kulak çöp çubuğu yardımıyla temizlenmesi gerekmektedir. Böylelikle akord orjinal haline dönebilecek ve sağlıklı bir icraya kolaylık sağlayacaktır.
                </blockquote>
                <br></br>
                <blockquote>
                    <span className='text-danger'>KLARNET ENSTRÜMANI GÜDERİ DEĞİŞİMİ</span> : Klarnet enstrümanı güderileri, ağızdan çıkan buharın su olarak dönüşümü ile birlikte belli bir zaman sonra yumuşamaya başlar ve yumuşayan güderi zamanla üzerini kapadığı deliğin hava kaçırmasına sebebiyet verir. Bu sebeble de enstrüman, orjinal halinde ki performansı gösteremeyip, seste bozulmalara neden olur. İşte bu bozulmaları yok etmek adına eski güderiler yenisi ile değişdirilerek, enstrümandan hava kaçmasına engel olunmalıdır.
                </blockquote>
                <br></br>
                <blockquote>
                    <span className='text-danger'>KLARNET REGLAJ AYARININ YAPILMASI</span> : Enstrüman seçiminde dikkat edilmesi gereken en önemli mevzulardan biri olan reglaj ayarı ; İlk enstrüman seçiminde kişinin karşısına çıkabileceği gibi, belli bir zaman sonra da ayarlanması gereken husus haline gelebilmektedir. Reglaj yani tuşe ayarı ; hem icracının daha sağlıklı bir icra yapabilmesi hemde enstrümanını yüzde yüz verim alarak kullanabilmesi adına ciddi bir önem arz etmektedir. Klarnet bakım ve onarım teknisyenleri tarafından yapılması önemli olan bu ayar ; fazla açılan yada az açılan perdelerin-tuşelerin doğru seviyeye getirilmesi ile sağlıklı sonucu verecektir. Klarnet entonasyonunda da önemli bir rol oynayan reglaj ayarı; enstrümanınızdan tiz bir frekans yada pes bir frekans almanıza sebebiyet verebilir.
                </blockquote>
                <br></br>
                <blockquote>
                    <span className='text-danger'>AĞAÇ KLARNETLERİN SOĞUK HAVADA PES, SICAK HAVADA TİZ KALMASI</span>
                    : Özellikle ağaç klarnetlerde (ısıya karşı fazla duyarlı olması sebebiyle) sıcak-soğuk hava sirkülasyonundan dolayı akord değişimi sık sık yaşanmaktadır.
                    Kış aylarında enstruman yarım, veya bazen bir sese yakın pes kalmaktadır. Yazın ise akort tiz gelmektedir. 10 dk kadar sıcak üflemeden sonra bu sorun yarım ses pes kalan klarnetlerde kısmende olsa düzelmekte düzelmediği taktirde ise enstrümanın kendi içinde ki akordunun sorunlu olduğu gözlemlenerek ayarlanması gerekmektedir. Yapılması gereken işlem, klarnet bakım onarım teknisyenleri öncülüğünde yapılmalı ve iyi bir üfleme tekniğine sahip klarnetistler tarafından akord cihazı ile sesler tek tek kontrol edilmelidir.
                </blockquote>
                <br></br>
                <blockquote>
                    <span className='text-danger'>KLARNETTE AKORD PROBLEMİ </span>
                    :
                    Uzakdoğu yapımı klarnetlerde sık sık karşılaşabileceğimiz bu durum, enstrümanın kendi içinde akord probleminin olmasıdır yani başka bir deyişle seslerin kendi içinde uyumsuzluğudur.
                    Entonasyon çalışmasında her sesi tek tek incelediğimiz de örneğin 1.oktav La sesi Tiz,
                    Sol sesi Pes, Re sesi Tiz, Mi sesi pes, gibi durumlar sazın sıkıntılı olduğunun göstergesidir ! Çünkü bir uyumsuzluk söz konusudur !
                    Uzak doğu yapımı klarnetlerde elbette mükemmel bir entonasyon bekleyemeyiz ve nihayetinde 5000-6000 tl fiyatında olan enstrümanlar ile de mukayese edemeyiz. Ancak en azından enstrümanın kendi için de akordunun genel anlamda tiz yada genel anlamda pes olmasına dikkat etmeliyiz. Çünkü bu sorun bir nebze de olsa doğru baril seçimi ile çözülebilmektedir.
                    Yeni başlayan arkadaşlarımız bu sorunları yaşamamak için ; Enstrümanlarını almadan önce iyi bir hocaya kontrolünü yaptırarak ve enstrümanların seçtirerek almalıdırlar.

                </blockquote>
                <br></br>
                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
