import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/course.css'

import baglama from '../assets/img/baglama.png';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'
import WhatsAppContact from '../components/WhatsAppContact';

function InstrumentCoursesScreen() {
    return (
        <div>
            <TopNavbar></TopNavbar>
            <WhatsAppContact />
            <div className='container-color'>
                &nbsp;
                <h1 style={{ textAlign: 'center' }}>KURSLARIMIZ</h1>
                <Container >
                    <Row>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/color/65/000000/guitar.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Klasik Gitar Kursu</h3>
                                <span>Uzman gitaristlerle klasik gitar dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/klasik-gitar-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/stickers/96/000000/guitar.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Akustik Gitar Kursu</h3>
                                <span>Uzman gitaristlerle akustik gitar dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/akustik-gitar-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/color/96/000000/rock-music.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Elektro Gitar Kursu</h3>
                                <span>Uzman gitaristlerle elektro gitar dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/elektro-gitar-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/external-flaticons-flat-flat-icons/96/000000/external-grand-piano-musical-instruments-flaticons-flat-flat-icons.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Piyano Kursu</h3>
                                <span>Uzman piyanistlerle piyano dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/piyano-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/emoji/96/40C057/violin-emoji.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Keman Kursu</h3>
                                <span>Uzman kemanistlerle keman dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/keman-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={baglama} alt='...' height={100} />
                                <br></br>
                                <h3>Bağlama Kursu</h3>
                                <span>Uzman müzisyenler eşliğinde bağlama dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/baglama-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/external-nawicon-outline-color-nawicon/96/000000/external-microphone-communication-nawicon-outline-color-nawicon.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Şan Eğitimi ve Vokal Koçluğu</h3>
                                <span>Uzman eğitmenlerimiz ile şan dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/san-egitimi-ve-vokal-koclugu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/external-photo3ideastudio-flat-photo3ideastudio/96/000000/external-singer-celebration-photo3ideastudio-flat-photo3ideastudio.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Konservatuara ve Güzel Sanatlara Hazırlık</h3>
                                <span>Uzman eğitmenlerimiz ile konservatuara dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/konservatuara-ve-guzel-sanatlara-hazirlik' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                        <Col style={{ paddingTop: 25, paddingBottom: 25, border: '1px solid black' }} md={4}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={"https://img.icons8.com/external-icongeek26-flat-icongeek26/96/000000/external-veena-india-icongeek26-flat-icongeek26.png"} alt='...' height={100} />
                                <br></br>
                                <h3>Kabak Kemane Kursu</h3>
                                <span>Uzman müzisyenler eşliğinde Kabak Kemane dersleri.</span>
                                <br></br>
                                <br></br>
                                <Button href='/kabak-kemane-kursu' variant="outline-warning">Daha Fazlasını Gör</Button>
                                <br></br>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            &nbsp;

            <Footer></Footer>
        </div>
    );
}

export default InstrumentCoursesScreen;