import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import amfi1 from '../assets/img/amfi/amfi1.png'
import amfi2 from '../assets/img/amfi/amfi2.png'
import amfi3 from '../assets/img/amfi/amfi3.png'
import amfi4 from '../assets/img/amfi/amfi4.png'
import amfi5 from '../assets/img/amfi/amfi5.png'
import amfi7 from '../assets/img/amfi/amfi7.png'
import amfi8 from '../assets/img/amfi/amfi6.jpg'
export const AmfiFixScreen = () => {
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={amfi1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={amfi2}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={amfi3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={amfi4}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    <Col md>
                        <img src={amfi5} height="500" alt='amfi'/>
                    </Col>
                    <Col md>
                        <h2>Klasik Gitar Tamiri</h2>
                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Gitar Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Gitar eğitimi özel ders olarak verilmektedir.</li>
                            <li>Gitar Dersleri  haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Birebir Gitar dersi, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Gitar Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  gitar ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Klasik gitar kursu / Akustik gitar kursu  / Elektro gitar kursu</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={8}>
                        <h2>Klasik Gitar Tamiri</h2>
                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Gitar Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Gitar eğitimi özel ders olarak verilmektedir.</li>
                            <li>Gitar Dersleri  haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Birebir Gitar dersi, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Gitar Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  gitar ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Klasik gitar kursu / Akustik gitar kursu  / Elektro gitar kursu</li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <img src={amfi8} height="350" alt='amfi'/>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md>
                        <img src={amfi7} height="500" alt='amfi'/>
                    </Col>
                    <Col md>
                        <h2>Klasik Gitar Tamiri</h2>
                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Gitar Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Gitar eğitimi özel ders olarak verilmektedir.</li>
                            <li>Gitar Dersleri  haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Birebir Gitar dersi, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Gitar Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  gitar ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Klasik gitar kursu / Akustik gitar kursu  / Elektro gitar kursu</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
