import React from "react";

import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import banner53 from '../assets/img/pagesBanner/banner53.png';
import banner54 from '../assets/img/pagesBanner/banner54.png';
import banner55 from '../assets/img/pagesBanner/banner55.png';
import banner56 from '../assets/img/pagesBanner/banner56.png';

import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function SingingCourseScreen() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="İstanbul Pendik Kurtköyün en kapsamlı ve en tecrübeli müzik okulu. Black Music Fox Sanat Okulu." />
                <meta name="keywords" content="
                şan dersi,
                şan kursu,
                kurtköy şan dersi,
                kurtköy şan kursu,
                şan eğitimi,
                kurtköy şan eğitimi,
                pendik şan dersi,
                pendik şan kursu,
                pendik şan eğitimi,
                istanbul şan eğitimi,
                istanbul şan dersi,
                istanbul şan kursu,
                şan eğitimi fiyatları,
                şan kursu fiyatları,
                şan dersi fiyatları,
                ses eğitimi,
                anadolu yakası şan dersi,
                anadolu yakası şan kursu,
                anadolu yakası şan eğitimi,
                istanbul ses eğitimi,
                kurtköy ses eğitimi,
                pendik ses eğitimi,
                anadolu yakası ses eğitimi,
                ses eğitimi kursları,
                vokal koçluğu,
                kurtköy vokal koçluğu,
                pendik vokal koçluğu,
                istanbul vokal koçluğu,
                anadolu yakası vokal koçluğu,
                san dersleri,
                kurtköy san dersleri,
                pendik san dersleri,
                istanbul san dersleri,
                anadolu yakası san dersleri,
                san kursu,
                "></meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Şan Eğitimi ve Vokal Koçluğu</title>
            </Helmet>
            <TopNavbar />
                <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner53}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner54}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner55}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner56}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Şan Eğitimi ve Vokal Koçluğu</h1>
                        <br></br>
                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>
                        <ul>
                            <li>Kursumuzda Şan eğitimi özel ders olarak verilmektedir.</li>
                            <li>Dersler; haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Eğitimler, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Şan  Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  bağlama ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Her öğrencinin seviyesine uygun olarak bağlamanın tarihçesini, nota ve ritim öğrenimini, bağlama çalım tekniklerini aktarmakla başlamaktayız.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <blockquote>
                            Şan eğitimi, ses eğitimi demektir. Şan eğitiminde nefesin doğru kullanımı, sesin doğru ayarlanması ve diyaframın da doğru kullanılması öğretilir. Müziğe ilgisi olan herkesin şan eğitimi alması gereklidir. Şan eğitimi nedir ve şan dersi faydaları nelerdir detayları;

                            Şan dersinin faydaları arasında doğru ve düzgün tonlama yapmak vardır. Ses gürlüğünü de ayarlamak bu sayede mümkündür.

                        </blockquote>
                        <br />
                        <h4>Şan Eğitimi Nedir, Ne İşe Yarar?</h4>
                        <blockquote>
                            Doğru şarkı söylemek için ses tellerini eğitmek oldukça önemlidir. Bu sayede sesi daha bilinçli kullanabilir, nefesini doğru ayarlayabilir ve ses tellerinizi de eğitebilirsiniz.

                            Ses kontrolü özellikle sesini kullanan sanatçılar için önemlidir. Bu nedenle müzik alanında çalışan ve müzikle ilgisi olan herkesin şan eğitimi alması önerilir. Şan eğitimi alan kişiler her türlü müziği daha rahat bir şekilde söyleyebilir. Bu sayede seslerini her müzik için ayarlayabilirler.
                        </blockquote>
                        <br />
                        <h4>Şan Dersi Faydaları Nelerdir?</h4>
                        <blockquote>
                            Ses tellerinin eğitilmesi için alınan şan dersi ayrıca düzgün ve doğru tonlama yapabilmek için de alınır. Ses gürlüğünü ayarlayabilmek, diyafram, karın ve ağız boşluğu gibi alanları bu sayede doğru kullanmak mümkündür. Nefesi doğru alma ve doğru kontrol edebilme, doğru kullanma da şan dersinin faydaları arasındadır.

                            Şan dersini müziğe ilgisi olan herkesin alması gerekmektedir. Ses kontrolünü en iyi şekilde kullanmak için şan dersi önerilir. Şan dersi ile sesini daha doğru kullanmayı öğrenebilir, doğru nefes alabilir ve sesini doğru kontrol edebilirsiniz. Genelde sanatçılar ve müzik ile ilgilenen kişiler şan dersi alarak kendilerini geliştirmekte ve seslerini daha iyi kontrol edebilmektedir.
                        </blockquote>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}