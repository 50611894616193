import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner34 from '../assets/img/pagesBanner/banner34.png'
import banner35 from '../assets/img/pagesBanner/banner35.png'
import banner88 from '../assets/img/pagesBanner/banner88.png'

export const KabakKemaneFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner34}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner35}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner88}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Kabak Kemane Tamiri</h2>
                        <blockquote>
                            YAYLI MÜZİK ALETLERİNİN TAMİR VE BAKIMI Keman, Viyola, Viyolonsel, Kontrbas gibi yaylı enstrumanlarınızın kırık, çatlak, tel değişimi, köprü ve can direği ayarları,burgu sorunları,klavye tesviyesi gibi her türlü tamir ve bakımını profesyonel bir titizlikle yapmaktayız
                        </blockquote>
                    </Col>
                </Row>
                &nbsp;

                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
