import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner4 from '../assets/img/pagesBanner/banner4.png'
import banner7 from '../assets/img/pagesBanner/banner7.png'
import banner21 from '../assets/img/pagesBanner/banner21.png'
import banner63 from '../assets/img/pagesBanner/banner63.png'

export const KemanFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner4}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner7}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner21}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner63}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Keman Tamiri</h2>
                        <blockquote>
                            YAYLI MÜZİK ALETLERİNİN TAMİR VE BAKIMI Keman, Viyola, Viyolonsel, Kontrbas gibi yaylı enstrumanlarınızın kırık, çatlak, tel değişimi, köprü ve can direği ayarları,burgu sorunları,klavye tesviyesi gibi her türlü tamir ve bakımını profesyonel bir titizlikle yapmaktayız
                        </blockquote>
                        <h4>Enstrüman Onarımı ve Restorasyonu</h4>
                        <h5>Cihaz Kurulumu ve Ton Ayarı</h5>
                        <blockquote>
                            onu ve çalınabilirlik potansiyelini optimize etmek için keman, viyola, viyolonsel veya basınızda özel kurulumlar ve ton ayarlamaları yapılmaktadır.
                        </blockquote>
                        <h5>Yay Tamiri ve Bakımı</h5>
                        <blockquote>
                            Keman, viyola, viyolonsel ve bas yayları için yay tamir ve rehabilitasyon hizmetleri mevcuttur. Yayının temizlenmesi ve cilalanması ve kurbağa gözünün yağlanması her bakımda dahildir.
                            Mevcut en iyi Moğol aygır kıllarını kullanıyoruz.
                            Müşteri tarafından yeni saçı korumasız bırakması istenmedikçe, onarımlar premium reçine ile yapılır.
                            Planlanmış randevu alınarak aynı gün servis talep edilebilir. Ayrıntılar için lütfen arayınız.
                        </blockquote>
                    </Col>
                </Row>
                <br></br>
                <h5>Keman Bakımını Aralıklarla Yaptırmalısın!</h5>
                <blockquote>
                    Atölye ekibimiz, eski cihazlarda sıklıkla gerekli olan kapsamlı onarımları yapıp yapmama veya başka bir cihaz satın almanın sizin için daha iyi bir seçenek olup olmayacağına karar vermenize yardımcı olabilir.
                    Bazı durumlarda, bir alet onarım maliyetine değmeyebilir. Eski cihazınızla ilgili sorularınız varsa, randevu almak için lütfen bizi arayın.
                </blockquote>
                <br></br>
                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
