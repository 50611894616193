import React from "react";
import { Helmet } from "react-helmet";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import Banner16 from '../assets/img/pagesBanner/banner16.png';
import Banner24 from '../assets/img/pagesBanner/banner24.png';
import Banner32 from '../assets/img/pagesBanner/banner32.png';

import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function ClassicGuitarCourseScreen() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="İstanbul Pendik Kurtköyün en kapsamlı ve en tecrübeli müzik okulu. Black Music Fox Sanat Okulu." />
                <meta name="keywords" content="
                                                gitar dersi,
                                                gitar kursu,
                                                gitar kursu fiyatları,
                                                elektro gitar kursu,
                                                gitar çalma dersleri,
                                                klasik gitar dersi,
                                                gitar kursu ücretleri,
                                                gitar nasıl öğrenirim,
                                                gitara dersleri,
                                                müzik kursu fiyatları,
                                                elektro gitar kursları,
                                                enstrüman kursu,
                                                gitar dersi kurtköy,
                                                pendik gitar kursları,
                                                pendik elektro gitar dersi,
                                                kurtköy gitar kursu,
                                                kurtköy gitar dersi,
                                                kurtköy klasik gitar dersleri,
                                                kurtköy elektro gitar kursu,
                                                kurtköy elektro gitar dersi,
                                                kurtköy gitar,
                                                yetişkinler için gitar dersi,
                                                yetişkinler için gitar kursu,
                                                çocuklar için gitar dersi,
                                                çocuklar için gitar kursu,
                                                pendik gitar kursu,
                                                pendik gitar dersi,
                                                pendik klasik gitar dersi,
                                                istanbul gitar dersi,
                                                istanbul gitar kursu,
                                                istanbul klasik gitar dersi,
                                                istanbul klasik gitar kursu,
                                                istanbul elektro gitar dersi,
                                                istanbul elektro gitar kursu,
                                                istanbul akustik gitar dersi,
                                                istanbul akustik gitar kursu,
                                                anadolu yakası gitar desi,
                                                anadolu yakası gitar kursu,
                                                anadolu yakası elektro gitar dersi,
                                                anadolu yakası elektro gitar kursu,"
                >
                </meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Klasik Gitar Kursu</title>
            </Helmet>
            <TopNavbar />
                <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner16}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner24}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner32}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Klasik Gitar Kursumuz</h1>
                        <br></br>
                        <p>Gitar Çalmayı En eğlenceli</p>
                        <p>En uygun fiyata</p>
                        <p>Kolay şekilde öğrenmek istiyorsanız</p>

                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>

                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Gitar Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Gitar eğitimi özel ders olarak verilmektedir.</li>
                            <li>Gitar Dersleri  haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Birebir Gitar dersi, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Gitar Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  gitar ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Klasik gitar kursu / Akustik gitar kursu  / Elektro gitar kursu</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <blockquote>
                            Herkes bir dönemde bir müzik aleti çalabilmeyi istemiştir ve ilk akla gelen en popüler olan gitardır. Gitar çalmayı öğrenmek düşünüldüğü kadar zor değildir. Gitar diğer çalgılara göre daha hızlı öğrenim gösteren bir çalgıdır. İlk yapmanız gereken şey başlamaya karar vermektir. Müzik alanında bilginizin olup olmaması önemli değildir. Çünkü öğrenim süreçlerinde öğretmen en etkili rolü üstlenir. Bizler de Black Music Fox Okulu  olarak eğitim kalitesi konusunda özenle seçilmiş ve alanında kendini kanıtlamış uzman eğitmenlerimizle bu işi bilen, riskleri ortadan kaldıran, içinizde şüphe bırakmayacak bir hizmet sunmaya çalışıyoruz.
                        </blockquote>
                        <ul>
                            <li>
                                <blockquote>
                                    Gitar dersinde repertuarımız çok geniş olmakla birlikte klasik eserlerden güncel şarkılara kadar birçok teorik nota bilgisi ve müzik eğitimi verilir.
                                    Sonrasında gerek duyulan nota ve nazari bilgiler dersin ilk aşamasında öğretilir. Black Music Fox Okulun da eğitmenimizle yapacağınız parmak egzersizleri, etütler, ritim kalıpları gibi teknikler de vardır. İki üç hafta içerisinde parça çalınmaya başlanır. Bu sistemle birlikte öğrencileri çok sıkmamış oluruz. Müzik teorisi ve solfej eğitimiyle de desteklenen gitar dersimiz kişinin yeteneğine ve çalışmalarına göre farklılık gösterse de Black Music Fox Okulun da kısa sürede iyi bir seviyeye gelmiş olacaksınız.

                                </blockquote>
                            </li>
                            <li>
                                <blockquote>
                                    Bunlarla birlikte görme, duyma, kas hareketlerini kullanabilmenizin yanı sıra beyninizin birden fazla bölgesini çalıştırarak çok yönlü bir koordinasyon sağlamaktadır. Ayrıca çocukların motor gelişiminde, ince motor kaslarında da daha hızlı bir ilerleme olacak ve algıları açılacaktır. Kursumuzda  yapacağımız alıştırmalarla bu durum iyi ve doğru bir şekilde gelişecektir. Black Music Fox Okulun da ücretsiz deneme dersleri yaptırıp kayıt yapma zorunluluğu yoktur. Birçok fırsat sağlamaya çalışan Black Music Fox Okulu  olarak her yaştan müzik tutkunu olan sizleri gitar kursumuza bekliyoruz.
                                </blockquote>
                            </li>
                            <li>
                                <blockquote>
                                    Hiç kimse yoktur ki müzikle ilgisi olmasın. Müziksiz insan yaşamını hayal etmek imkânsız. Günümüzün stresli yaşamından bir an olsun uzaklaşmak için, insan hayatının, müziğin bir parçası olabilmek için Black Music Fox Okulu  olarak buradayız.
                                </blockquote>
                            </li>
                            <li>
                                <blockquote>
                                    Müzik genel olarak insanların düşüncelerini duygularını anlattıkları bir sanat değil midir? Bizlerde Black Music Fox Okulu  olarak insan hayatının her döneminde her anında, her saatinde, her yılında bazen ilhamı olabilmek, bazen nefesi, bazen bestecisi, bazen de kendisi olmaya çalışıyoruz. İnsan ruhu kulaktan geçer.
                                </blockquote>
                            </li>
                        </ul>

                        <blockquote>
                            Kursumuzda verilen gitar eğitimimizin bir diğer amacı boş vakitlerinde bir hobiyle, bir uğraşla geçiren öğrencilerin insanlarla olan iletişimlerinde benlik kavramında gitar eğitmenlerimizle olsun diğer ekip arkadaşlarımızla olsun kurulacak sıcak ortamda ve sıcak akıcı iletişimle özgüveni daha da öne çıkarmaktadır. Öğrencilerin müziği dinleme anlama, yorumlama ve müzik bilgisi oluşturma gibi davranışı da gelişmektedir. Biraz gayret ve zamanı doğru kullanmak Black Music Fox Okulu sizin bu işi tam anlamıyla öğrenmenize yetecektir. İlerleyen süreçlerde parçaları çalabildiğiniz gibi kendi bestelerinizi de yapmaya başlayacaksınız.
                            Peki bu bahsettiğimiz insan ruhunu bu denli etkileyen gitarın başlangıcında ve sonrasında neler mi oluyor? Gitar ilk keşfinden günümüze kadar birçok gelişim aşaması geçirmiştir. Farklı ağaç türlerinden yapılan gitarlar genellikle altı telli olur. Birçok müzik türünde kullanılan gitarda çok fazla çeşitler vardır. Bu çeşitlilik nedeniyle de insanlık tarihinden bu yana gitar her zaman insanların ilgisini çekmektedir. Her türlü duygu değişimlerinde ve aktivitelerde gitarın önemli bir rolü vardır. Spor yaparken, mutluyken, üzgünken, eğlenirken ve bu gibi durumlarda hep müziğe yönelmişizdir. Ve kulaklarımızın pasını silen gitar sesi hep bizimle olmuştur.
                        </blockquote>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}