import React from 'react'
import Container from 'react-bootstrap/Container'
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import example from '../assets/img/pagesBanner/example.png'
import WhatsAppContact from '../components/WhatsAppContact';

import kemence from '../assets/img/kemenche.png'
import kontrobas from '../assets/img/double-bass.png'
import kabakkemane from '../assets/img/kabakkemane.png'
import cello from '../assets/img/cello.png'
export const StringInstrumentsScreen = () => {
    return (
        <>

            <TopNavbar />
            <br />
            <WhatsAppContact />
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={example}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>

                <br />

                <Row>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Keman Tamiri</h2>
                        <img src="https://img.icons8.com/color/96/null/violin.png" alt='keman tamiri'/>
                        <a role="button" tabindex="0" href="/keman-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Çello Tamiri</h2>
                        <img src={cello} alt='cello tamiri'/>
                        <a role="button" tabindex="0" href="/cello-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={4}>
                        <h2>Kemençe Tamiri</h2>
                        <img src={kemence} alt="kemence"></img>
                        <a role="button" tabindex="0" href="/kemence-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col style={{ textAlign: 'center' }} md={6}>
                        <h2>Kontrabas Tamiri</h2>
                        <img src={kontrobas} alt='kontrobas tamiri'></img>
                        <a role="button" tabindex="0" href="/kontrobas-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                    <Col style={{ textAlign: 'center' }} md={6}>
                        <h2>Kabak Kemane Tamiri</h2>
                        <img src={kabakkemane} alt="kabak kemane tamiri" ></img>
                        <a role="button" tabindex="0" href="/kabak-kemane-tamiri" class="d-grid gap-2 btn btn-warning">Daha Fazlası İçin</a>
                    </Col>
                </Row>
                <br />
            </Container>
            <br />
            <Footer />
        </>
    )
}
