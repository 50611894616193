import React from 'react'
import Container from 'react-bootstrap/Container';
import { useMediaQuery } from 'react-responsive'
const GoogleMaps = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (

        <Container>
            {isDesktopOrLaptop &&
                <iframe title='black music fox kurtköy' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12057.803795647158!2d29.3079767!3d40.927783!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa1db18057d36ed64!2sBlack%20Music%20FOX%20Sanat%20Okulu!5e0!3m2!1str!2str!4v1670331215479!5m2!1str!2str" width="1300" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            } { isTabletOrMobile &&
<iframe title='black music fox kurtköy' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12057.803795647158!2d29.3079767!3d40.927783!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa1db18057d36ed64!2sBlack%20Music%20FOX%20Sanat%20Okulu!5e0!3m2!1str!2str!4v1670331215479!5m2!1str!2str" width="375" height="400" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            }
        </Container>
    )
}

export default GoogleMaps