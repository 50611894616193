import React from "react";
import { Helmet } from "react-helmet";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import Banner19 from '../assets/img/pagesBanner/banner19.png';
import Banner26 from '../assets/img/pagesBanner/banner26.png';
import WhatsAppContact from "../components/WhatsAppContact";

export default function AboutUsScreen() {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="Black Music FOX Akademi Okulumuzda ;
                    Klasik Gitar, Elektro Gitar, Akustik Gitar, Uzun Sap ve Kısa Sap Bağlama, Keman, Piyano, Kabak
                    Kemane, Konservatuara Hazırlık, Güzel sanatlara Hazırlık dersleri vermekteyiz. Aynı zamanda tüm
                    enstrümanların ikinci el ve sıfır alım satımı mevcut. Bunların yanında tüm enstrümanların tamirat
                    tadilat bakım onarımı da yapılmaktadır. Uzman kadromuzla bölgenin en iyi okulu ve mağazası
                    olmaktan gurur duyuyoruz." />
                <meta name="keywords" content="Black Music Fox Sanat Okulu Hakkımızda ,Hakkımızda, Klasik Gitar Kursu, Klasik Gitar Dersi ,Elektro
                    Gitar Dersi ,Elektro Gitar Kursu,Akustik Gitar Dersi,Akustik Gitar Kursu,Bağlama Kursu, Bağlama
                    Dersi,Piyano Kursu,Piyano Dersi,Keman Dersi,Keman Kursu,Şan Eğitimi,Vokal Koçluğu,Kabak Kemane
                    Dersi,Kabak Kemane Kursu,Konservatuara Hazırlık Dersi,Konservatuara Hazırlık Kursu,Müzik Aletleri
                    Mağazası,Enstrüman Satış,Entrüman Tamir,Enstrüman Bakım,Enstrüman Onarım,Gitar Tamir,Keman
                    Tamir,Bağlama Tamir,">
                </meta>
                <meta name="author" content="Black Music Yazılım Ekibi"></meta>
                <title>Black Music Fox | Hakkımızda</title>
            </Helmet>
            <TopNavbar />
            <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner19}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner26}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                <br></br>
                <br />
                <Row>
                    <Col md={6}>
                        <h1>Hakkımızda</h1>
                        <br></br>
                        <blockquote>
                            BLACK MUSİC , her yaştan ve her seviyeden öğrencimizin sanat eğitimi alması amacıyla hizmetinize sunmuş bulunmaktayız.  BLACK MUSİC kendi müziğinize konsantre olabileceğiniz ve kendinizi geliştirebileceğiniz bir yapıdır. Müzik aleti ve müzik ekipmanı almak, enstrüman dersi almak, bireysel veya grup halinde prova yapmak gibi müziğe dair tüm ihtiyaçlarınızı tek bir adreste giderebilmeniz için tasarlanmıştır.
                            Akademimizde amaçladığımız; alışılagelmişin dışında alternatif ve yaratıcılığı ön plana çıkaracak bir eğitim sunmaktatir. Hedefimiz: Her öğrencinin ister amatör olarak ister profesyonel olarak ilgilendiği müziği bilinçli olarak dinleyebilmesi ve icra etmesi.
                            Bilimsel olarak ispatlanmıştır ki; her insan belli bir seviyeye kadar enstrüman çalmayı öğrenebilmektedir. Biz BLACK MUSİC ailesi olarak potansiyelinizi ortaya çıkarmak ilkesi ile yola çıktık. Alanında Türkiye’nin en iyi, genç ve dinamik eğitmenlerini, en hevesli, en kaliteli ve en renkli öğrencileri ile uzun zamandır buluşturmaktayız. İster hayat boyu size renk katacak bir hobiniz, ister sahnelerde ayakta alkışlanacak seviyede bir müzik bilginiz olması için sizleri de aramızda görmekten gurur duyacağız.

                        </blockquote>
                    </Col>
                    <Col md={6}>
                        <h1>Misyonumuz</h1>
                        <br></br>
                        <ul>
                            <li>Öğretme yerine öğrenme merkezli olmak.</li>
                            <li>Öğrencilerimizin sorgulama ve çözümleme becerilerini geliştirmek.</li>
                            <li>Keşfetme, anlama, yorumlama, konsantrasyon becerilerini geliştirmeye yönlendirmek.</li>
                            <li>Eğitimi, öğrencilerimizin yapı ve yönelimine uygun öğrenme metodları  ile programlamak.</li>
                            <li>Analitik düşünce ve duygusal yapıyı birlikte önemsetmek.</li>
                            <li>Müzik aracılığıyla kendini ifade etme ve iletişim kurmayı öğretmek.</li>
                            <li>Beyin-beden arasındaki senkronizasyonu ve koordinasyonu geliştirmek.</li>
                            <li>Sanatçı olma yolunda sosyal sorumluluk bilincini geliştirmek.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <h1 style={{ textAlign: 'center' }}>Eğitim Felsefemiz</h1>
                        <blockquote>
                            BLACK MUSİC olarak felsefemiz, öğrencilerimizin çalmayı istediği enstrümanı onlara sevdirmek ve çalışma disiplini kazandırmak. Kişinin enstrüman çalarken aynı zamanda da topluma yararlı bir birey haline getirmek .Kurumumuzda önce eğitim sonra öğretim ön plandadır.
                        </blockquote>
                        <blockquote>
                            BLACK MUSİC Ailesi olarak neler yapmaktayız;
                        </blockquote>
                        <ul>
                            <li>Elektro Gitar</li>
                            <li>Klasik Gitar</li>
                            <li>Akustik Gitar</li>
                            <li>Uzun Sap Bağlama</li>
                            <li>Kısa Sap Bağlama</li>
                            <li>Piyano</li>
                            <li>Kabak Kemane</li>
                            <li>Şan Eğitimi ve Vokal Koçluğu</li>
                            <li>Konservatuara Hazırlık</li>
                            <li>Güzel Sanatlara Hazırlık</li>
                        </ul>
                        <blockquote>
                            Derslerini birebir ve kişiye özel vermek. Talep doğrultusunda grup dersi olarak da ayarlanabilir.
                            Bunların yanında her ailenin bütçesine uygun en kaliteli ve uzun ömürlü enstrüman satışı yapmak ve aynı zamanda enstrüman tamir bakım ve onarım.

                        </blockquote>
                    </Col>
                </Row>
                <br></br>
                <br />
            </Container>
            <br></br>
            <br />
            <Footer></Footer>
        </div>
    );
}