import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com'
import { useState } from 'react';
import swal from 'sweetalert';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'
import GoogleMaps from '../components/GoogleMaps';

const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
}
export const ContactScreen = (props) => {
    const [{ name, email, phone, message }, setState] = useState(initialState)

    const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
    }
    const clearState = () => setState({ ...initialState })

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(name, email, phone, message)
        emailjs
            .sendForm(
                'blackmusic.ca', 'blackmusic.ca', e.target, '02iCa791infNr7QxA'
            )
            .then(
                (result) => {
                    console.log(result.text)
                    clearState()
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }

    const showAlert = () => {
        swal({
            title: "Mesaj Bilgisi",
            text: "Mesajınız başarılı bir şekilde iletildi.",
            icon: "success",
            confirmButtonText: "Tamam 🎸",
        });
    }


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="Bölgenin en iyi enstrüman kursu ve satış noktası.Enstrüman tamir bakım onarım." />
                <meta name="keywords" content=""></meta>
                <meta name="author" content="Black Music Yazılım Ekibi"></meta>
                <title>Black Music Fox | İletişim</title>
            </Helmet>
            <TopNavbar></TopNavbar>
            <br />

            <div class="contact-container">
                <div class="form">
                    <div class="contact-info">
                        <h3 class="title">İletişime Geçelim !</h3>
                        <p class="text">Aşağıdaki ayrıntılarla bize ulaşın ve formu ayrıntılarla doldurun.</p>
                        <div class="info">
                            <div class="social-information"> <i class="fa fa-map-marker"></i>
                                <p>Yenişehir Mahallesi, Mustafa Akyol Sokağı, Grup Center İş merkezi Daire:144, 34912 Pendik/İstanbul</p>
                            </div>
                            <div class="social-information"> <i class="fa fa-envelope-o"></i>
                                <p>blackmusic.ca@gmail.com</p>
                            </div>
                            <div class="social-information"> <i class="fa fa-mobile-phone"></i>
                                <p>0553 604 77 57</p>
                            </div>
                        </div>
                        <div class="social-media">
                            <p>Bizimle iletişime geçin</p>
                            <div class="social-icons"> <a href="/"> <i class="fa fa-facebook-f"></i> </a> <a href="/"> <i class="fa fa-twitter"></i> </a> <a href="/"> <i class="fa fa-instagram"></i> </a> <a href="/"> <i class="fa fa-youtube"></i> </a> </div>
                        </div>
                    </div>
                    <div class="contact-info-form"> <span class="circle one"></span> <span class="circle two"></span>
                        <form validate onSubmit={handleSubmit} autocomplete="off">
                            <h3 class="title">Bize Ulaşın</h3>
                            <div class="social-input-containers"> <input id='name' onChange={handleChange} required type="text" name="name" class="input" placeholder="İsim & Soyisim" /> </div>
                            <div class="social-input-containers"> <input id='email' onChange={handleChange} required type="email" name="email" class="input" placeholder="Email" />  </div>
                            <div class="social-input-containers"> <input id='phone' onChange={handleChange} required type="tel" name="phone" class="input" placeholder="Telefon" />  </div>
                            <div class="social-input-containers textarea"> <textarea id='message' onChange={handleChange} required name="message" class="input" placeholder="Mesaj"></textarea> </div> <input onClick={showAlert} type="submit" value="Gönder" class="btn-form" />
                        </form>
                    </div>
                </div>
            </div>

            <br></br>

            <GoogleMaps />
            <br></br>
            <Footer></Footer>
        </div>
    )
}