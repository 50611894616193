import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner22 from '../assets/img/pagesBanner/banner22.png'
import banner66 from '../assets/img/pagesBanner/banner66.png'

export const UdFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner22}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner66}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Ud Tamiri</h2>
                        <h4>1- Cızırtı - Çarpma</h4>
                        <blockquote>
                            Tele altlan mızrapla  elips şeklinde hareketlenen telin, özellikle kapalı pozisyonlarda klavyeye temas
                            edip çoklu  ses oluşturmasına  " cızırtı ", yine aynı hareket ile tellerin ses tablasına temas etmesine ise
                            " çarpma " denir. Yeni sazlarda özellikle " La " telinde, sert mızrap darbelerine bağlı bir miktar cızırtı
                            kabul edilir bir durumdur. Zamanla teknenin ,ses tablasının  ve klavyenin tel stresine verdiği cevapla
                            teller bir miktar yükselir bu cızırtı yok olur.
                            <br></br>
                            Devam eden veya sonradan oluşan cızırtılar ise bir çok sebebe bağlıdır.
                        </blockquote>

                        <ul>
                            <li>Klavye tesviyesindeki hatalar.</li>
                            <li>Zamanla oluşan, tellerin basıncına bağlı  deformasyonu.</li>
                            <li>Yüksek nem.</li>
                            <li>Hatalı tel.</li>
                            <li>Ses tablasında veya teknede kırık veya çatlak.</li>
                            <li>Mızraplıkta ve kafeslerde kırık, çatlak yada atma.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <blockquote>
                    Günümüzde maalesef çaldığımız sazlara uygun tel bulmakta zorlanıyoruz. ülkemizde tel sarımı  teknikleri
                    henüz istenilen seviyede değildir.  Tel sarımındaki  en ufak bir hata veya düşük malzeme kalitesi, telin kalibrasyonunda düzensizliğine sebep olup, klavyedeki cızırtıların en önemli nedenini oluşturmaktadır.

                    Tel ithalatında ise, popüler satış istatistiklerinden ziyade icracılarımızın geri bildirimi dikkate alınmalıdır.

                </blockquote>
                <br></br>
                <h4>2- Sap Atması</h4>
                <blockquote>
                    Udlarda sap atması, klavyenin öne gelmesi ve tellerin klavyeden uzaklaşıp yükselmesi şeklinde olur.
                </blockquote>
                <ul>
                    <li>Hatalı işçilik.</li>
                    <li>Düşük malzeme kalitesi</li>
                    <li>Düşük nem, havanın kuru olması</li>
                </ul>
                <blockquote>
                    Uygun nem ile beslenemeyen  ahşap malzemenin özsuyu kendisini büzmeye başlar. Bu büzme tellerin gergi uyguladığı yine doğru olur ve teller yükselip icrayı zorlaştırır. Tamiri genellikle, sap ile teknenin birleştirip  eksenden kesi  yapıldıktan sonra " kama " uygulanıp klavyenin geri atılmasıyla yapılır.
                </blockquote>
                <br></br>
                <h4>3-Eşik Düşmesi</h4>
                <blockquote>
                    Eşik düşmesini yarattığı sonuç açısından icracılar tarafından " sesin küsmesi " (kısa kalması, metalikleşmesi ) diye tarif edilir.
                    Aslında Udlarda yapı olarak geri dönüşü olmayan en önemli sorun budur.
                </blockquote>
                <ul>
                    <li>Ses tablasının ve balkonların fazla inceltilerek akortlanması</li>
                    <li>Alt ezik bilgesinde üst - alt balkonların fazla alçak olması.</li>
                    <li>Alt eşikde tel bağlama deliklerinin yüksekten delinmesi.</li>
                    <li>Havanın kuruması.</li>
                    <li>Yüksek tansiyonlu tel ve farklı akort sistemi.</li>
                </ul>
                <br></br>
                <blockquote>
                    Uygulanan  bu teknikler sazın ilk etapta güçlü -yüksek ses üretmesine, dolayısıyla kolay satılmasına
                    imkan sağlar. Yüksek ses hissiyatı artınca, sazla temas halinde olan icracıya saza hakim olduğuhissini vermektedir. şüphesiz bu durum başlangıçta icracı açısından etkileyici olacaktır. Oysaki iyi bir saz, sadece sesinin yüksekliği ile değil o yükseklikte sesin ne kadar kaliteli olduğu ile değerlenir.

                    Alt eşik bilgesi pes akortlanan sazlar, zamanla tellerin uyguladığında strese alt eşik bilgesinde yeterli direnci gösteremez ve alt eşiğin hemen önünde bir çukurlaştırma oluşturup alt eşik ekseninin ses tablası ile yaptığı açıyı kapatmasına neden olur.
                    <br></br>
                    - Dolayısıyla  alt eşiğin lokal  bir alanda tınlamasına,
                    <br></br>
                    - seslerin rengini - canlılığını kaybetmesine,
                    <br></br>
                    - kapalı pozisyonlarda, özellikle klavye sonu bölgesinde sesin gücünü kaybetmesine,
                    <br></br>
                    - seslerin çok hızlı düşmesine ( uzamanın  azalması ),
                    <br></br>
                    - tellerin sertleşip icranın yorucu olmasına sebep olur.
                    <br></br>
                    Bu durum icracının çalgıdan soğumasının en önemli sebebidir. Alt eşiğe yakın mızrap darbelerinde, sesler metalik ve kısa bir yapı alacağından icracı telleri alt eşikten uzakta mızraplamaya başlar. Bu tavır "sadece tellerin " hareketlenmesine, eşiğe yakın pozisyonlar ise " tellerin kasadan daha fazla ton almasın? " sağlar.
                    iyi bir saz, kendini alt eşiğe yakın, sesleri metalikleştirmeden çaldıran sazdır.

                    Kullanıma bağlı olarak ses tablası zamanla bir miktar yerleşir bu duruma çekme denir. Udlarda " çekme ",
                    eşik düşmesi gibi alt eşiğin  hemen önünde  çukurlaşma şeklinde değil, klavye başlangıcı ile alt eşik arasında merkezi küçük deliklerin alt kenarları olucak şekilde tatlı bir eğimdir.  Bu eğim, sazın
                    gerçek akustik kimliğini bulacağı uzun yıllar sonunda oluşması gerekir. Eşik düşmesi sorunu ses tablasının değişimini gerektiren masraflı bir işlemdir

                </blockquote>
                <br></br>
                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
