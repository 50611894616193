import React from "react";

import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import banner34 from '../assets/img/pagesBanner/banner34.png';
import banner35 from '../assets/img/pagesBanner/banner35.png';
import banner38 from '../assets/img/pagesBanner/banner38.png';

import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function KabakKemaneCourseScreen() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="Black Music FOX Sanat Okulu olarak 6 yılımızda siz değerli ailemiz için bütcenize ve ihtiyacınıza göre gerekli enstrümanı mağazamızda bulunması durumunda hemen elimizde olmaması durumunda var olan 19 toptan ve parekende alım yapabileceğimiz distribütör firmalarından en hızlı ve güvenilir şekilde sizlere ulaştırıyoruz." />
                <meta name="keywords" content="
                kabak kemane kursu,
                kabak kemane dersi,
                Kurtköy kabak kemane dersi,
                kurtköy kabak kemane kursu,
                Pendik kabak kemane dersi,
                pendik kabak kemane kursu,
                kabak kemane eğitimi,
                istanbul kabak kemane dersi,
                istanbul kebak kemane kursu,
                anadolu yakası kabak kemane dersi,
                anadolu yakası kabak kemane kursu,
                "></meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Kabak Kemane Kursu</title>
            </Helmet>

            <TopNavbar />
                <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner34}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner35}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner38}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Kabak Kemane Kursu</h1>
                        <br></br>
                        <p>En eğlenceli metot ile</p>
                        <p>En uygun fiyata </p>
                        <p>Kolay şekilde öğrenmek istiyorsanız</p>
                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>
                        <blockquote>
                            Kişiye Özel Ders Programı ile ister  Hobi Kabak Kemane Dersi Olarak İsterseniz Konservatuar Sınava Hazırlık için Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Kabak Kemane eğitimi özel ders olarak verilmektedir.</li>
                            <li>Dersler; haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Eğitimler, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Kabak Kemane  Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  Kabak kemane  ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Her öğrencinin seviyesine uygun olarak Kabak Kemanenin tarihçesini, nota ve ritim öğrenimini, Kabak kemane çalım tekniklerini aktarmakla başlamaktayız.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <blockquote>
                            Kabak kemane, Türk halk müziği'ndeki telli, yaylı ve deri kapaklı sazların tek örneğidir. Menşei Orta Asya'ya dayanmaktadır. Kabak kemane, Türkiye’de özellikle Batı Anadolu’da Ege Bölgesi’nde) yaygın olarak kullanılan bir sazdır. Kabak, kabak kemane, rebap (Güneydoğu Anadolu’da rubaba, Hatay yöresinde hegit) ve ıklığ gibi adlar ile bilinmektedir. Orta Asya Türkmenlerinin Gijek adını verdiği ve Azerbaycan halk müziğinde Kemança adıyla kullanılan çalgı da aynı köktendir. Gövdesi kabak veya hindistan cevizi, göğsü deri, iki veya üç telli olan bir halk çalgısıdır. Yörelere göre farklılık gösterir.
                            Su kabağı sap kısmından 1/3 oranında kesilir. Bu bölüme tekne adı verilir ve üzeri eskiden tavşan, günümüzde ise yürek zarı ile kaplanır. Tekne çapı yaklaşık 10-15 cm arasındadır. Tekneden sonra sap ve burgular gelir. Gövdenin en alt kısmında, çalgıcının kabak kemaneyi dizine dayayıp çalması için demir çubuk vardır. Bu çubuk aynı zamanda kabak ile sapın birbirini tutmasını da sağlar. Kemane perdesiz bir çalgı olduğu için her türlü kromatik ve komalı ses elde edilebilir. Ses genişliği, 2,5 oktavdır. Kabak kemane geçmişten günümüze kadar otantik görünüşünü korumuş bir halk çalgısıdır. Türkler kemane ve kemençe kültürlerini üç kıta üzerine yaymışlardır. "Iyık" Altaylarda "Yançak komus", Kırgızlarda "Kıl Kıyak", Türkmenlerde "Gıcak" gibi isimlerle anılmıştır. Kabak kemane yapılırken Su kabağı yukarı doğru incelen boğum altından kesilir ve üzerine yürek zarı veya deri geçirilir. Daha sonra kabağa ağaçtan sap (kol) monte edilir. Kemanenin aslı üç telli olup, daha geniş ses elde etmek için daha sonraları dördüncü bir tel ilave edilmiştir.
                            Tellerin ses düzeni ise, en kalın telden itibaren “LA–RE–LA-RE” şeklindedir. Piyanoya göre, “DO–FA–DO–FA” veya “Sİ–Mİ–Sİ–Mİ” olan bu akordu yapabilmek için, tellerinin en kalın telden itibaren bağlamada kullanılan, “Taşlanmış Kalın Sırma” (La) ve “Taşlanmış İnce Sırma (Re)” teli, 0.30 (La), 0.20 (Re), numaralı bağlama tellerinin takılması gerekir. Bugün ise, 4 telli kabak kemane ile birlikte, Halil Çelik ve Özgür Çelik tarafından yapılan 5 telli kabak kemane de kullanılmaktadır. 5 telli kabak kemaneye, 4 telli kabak kemaneden farklı olarak bir kalın tel daha ilave edilmiştir. Bu tel bağlamada da kullanılan “Bam BamTeli”dir  ve 5 telli Kabak Kemanenin akordu, “RE-LA-RE-LA-RE,” piyanoya göre ise, “FA-DO-FA-DO-FA” veya “Mİ-Sİ-Mİ-Sİ-Mİ” şeklindedir.[1] 5 telli kabak kemanenin dışında Halil Çelik tarafından 2000'li yıllardan itibaren üretilen 6, 7 ve 8 telli kabak kemaneler de mevcuttur.
                            2014 yılında ise; ölçüleri, tel numaraları ve akord sistemi genç müzisyen Cafer Nazlıbaş tarafından belirlenen 6 telli kabak kemane icad edilmiştir. Bu, yapılan ilk 6 telli kemane olup, sahibine 6 telli kabak kemane mucidi unvanını getirmiştir. NAZLIBAŞ icadında klasik kabak kemane sesine bir oktav daha eklemiştir. Kemaneye bir ince ve bir kalın tel ilave edilerek peslerde ve tizlerde daha uç seslere ulaşabilmek, daha geniş bir ses aralığı elde edebilmek ve böylece daha çok eserin çalınabilmesine olanak sağlamak amaçlanmıştır. Ayrıca sesler kulağa artık çok daha hoş gelecektir.
                            Kabağın çapının büyük veya küçük olması elde edilecek sesin tiz veya pes olması sonucunu doğurur. İki eşik arası (üst ve alt eşik) normal şartlarda 32–33 cm. uzunluğunda olmalıdır. Ancak derinin az veya çok gergin olması bu uzaklığın değişmesinde etkendir. Su kabağının yanı sıra dut ağacından da kemane yapılmaktadır. Şu anda kemanede normal bağlama telleri (çelik ve sırma) kullanılmaktadır. Ancak kemanenin doğal yapısı ile orantılı olarak keman telleri de kullanılabilir. Kemane at kılıfından yapılmış yay ile çalınır. İyi, kaliteli ve gür ses elde etmek için kıllar üzerine reçine sürülür. Tizden peste doğru, bağlama ya göre 1-Re, 2-La, 3-Re, 4-Sol şeklinde (tunere göre F,C,F,A#) akort edilir.
                        </blockquote>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}