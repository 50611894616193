import React from 'react'

const WhatsAppContact = () => {
    return (
        <div style={{ position: 'fixed', bottom: 0, right: 0, width: 50, zIndex:9999 }}>
            <a href='https://wa.me/905536047757' target={"_blank"} rel="noopener noreferrer"><img src="https://img.icons8.com/color/48/null/whatsapp--v1.png" alt='iletisim'/></a>
        </div>
    )
}

export default WhatsAppContact