import React from "react";
import { Helmet } from "react-helmet";
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import Banner25 from '../assets/img/pagesBanner/banner25.png';
import Banner41 from '../assets/img/pagesBanner/banner41.png';
import Banner42 from '../assets/img/pagesBanner/banner42.png';

import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function BaglamaCourseScreen() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="İstanbul Pendik Kurtköyün en kapsamlı ve en tecrübeli müzik okulu. Black Music Fox Sanat Okulu." />
                <meta name="keywords" content="
                bağlama dersi,
                bağlama kursu,
                saz kursu,
                saz kursu fiyatları,
                bağlama dersi fiyatları,
                bağlama kursu fiyatları,
                saz dersi,
                en kolay saz dersi,
                en kolay bağlama dersi,
                yetişkinler için bağlama kursu,
                yetişkinler için bağlama dersi,
                çocuklar için bağlama kursu,
                çocuklar için bağlama dersi,
                saz nasıl öğrenirim,
                bağlama nasıl öğrenirim,
                saz dersleri,
                saz çalma dersleri,
                uzun sap bağlama dersi,
                uzun sap bağlama kursu,
                uzun sap saz dersi,
                uzun sap saz kursu,
                kurtköy bağlama dersi,
                kurtköy bağlama dersi,
                kurtköy saz dersi,
                kurtköy saz kursu,
                pendik bağlama kursu,
                pendik bağlama dersi,
                pendik saz kursu,
                pendik saz dersi,
                istanbul bağlama dersi,
                istanbul bağlama kursu,
                istanbul saz dersi,
                istanbul saz kursu,
                anadolu yakası bağlama dersi,
                anadolu yakası bağlama kursu,
                anadolu yakası saz kursu,
                anadolu yakası saz dersi,
                "></meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Bağlama Kursu</title>
            </Helmet>
            <TopNavbar />
            <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner41}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner25}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={Banner42}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Bağlama Kursumuz</h1>
                        <br></br>
                        <p>Bağlama Çalmayı</p>
                        <p>En eğlenceli method ile</p>
                        <p>En uygun fiyata</p>
                        <p>Kolay şekilde öğrenmek istiyorsanız</p>

                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>

                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Bağlama Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Keman eğitimi özel ders olarak verilmektedir.</li>
                            <li>Dersler; haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Eğitimler, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Bağlama Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  bağlama ile uygulamalı verilmektedir</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Her öğrencinin seviyesine uygun olarak bağlamanın tarihçesini, nota ve ritim öğrenimini, bağlama çalım tekniklerini aktarmakla başlamaktayız.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <blockquote>
                            Black Music Fox Okulun da bağlama derslerinde öncelikle duruş, oturuş, temel nota aktarımları ile başlanıp ilk aydan itibaren basit parçalar çalınmaya başlanmaktadır. Nota bilgileri akort ayarları, farklı bağlama düzenleri değişik yörelere ait eserler, popüler türküler, klasik halk türküleri çalınmaktadır.
                            Bağlama tüm ezgilere ve çok etkili sese sahip bir enstrümandır. Black Music Fox Okulu olarak uzman eğitmenlerimizle verilen kurslarımızın öncelikli amacı müzik sevgisini aşılamak, beynin mantıksal işleyişini arttırmak, müzik duygusunu geliştirmektir. Çünkü müzik aklımıza değil kalbimize seslenir. Hayatımız bir şarkıdır.
                        </blockquote>
                        <br />
                        <blockquote>
                            Kimi zaman neşelendirir bu şarkılar kimi zaman hüzünlendirir ama ne olursa olsun bizimledir ve içimizdedir. Çünkü insan hayatı müziğin bir parçasıdır. Bağlama öyle bir tindir ki kimsenin anlatamadığı ama herkesin bildiği şeyleri anlatır bizlere.
                            Bağlama direk ruhla bağlantı kurduğu için insan üzerindeki en etkili enstrümanlardan biridir. Hiç kimse yoktur ki müzikle ilgisi olmasın. Müziksiz insan yaşamını hayal etmek imkansızdır. Günümüzün stresli yaşantısından bir an olsun uzaklaşmak için insan hayatının, müziğin bir parçası olabilmek için Black Music Fox Okulu  olarak buradayız.
                        </blockquote>
                        <br />
                        <blockquote>
                            Bağlama çalmak ve tellerle olan ilişkileri güçlendirebilmek, görme ve kas hareketlerimizi geliştirmek çok yönlü bir koordinasyon aktarmasını sağlamayı amaçlamaktayız.
                            Bağlama ülkemizde kullanılan ve en yaygın olan Türk halk çalgısıdır. Ebatlarına ve yörelerine göre bu çalgıya Tambura, Cura, Kopuz ırızva, Çögü, Bozuk, Divan sazı, Bağlama gibi isimler verilmiştir.
                        </blockquote>
                        <br />
                        <blockquote>
                            Bağlama Türk halk müziğimizin hemen hemen her bölgesinde en sık kullanılan, genelde yedi ve dokuz telli olup mızrap(tezene) ile çalınan ses genişliği yaklaşık iki oktav olan, tahta telli çalgıya bağlama denir. Bağlamanın atası olarak Kopuz gösterilmektedir. Bu iki çalgı da gerek çalım gerek şekil itibari ile birbirine oldukça benzerdir. Çalgının adını sapında bulunan perdelerin ve tellerin bağlanmasından esinlenerek konulduğu düşünülmektedir. Bağlama kısaca armudu andıran tekne (gövde) teknenin üzerine yapıştırılan göğüs ve sap kısımlarından oluşmaktadır. Göğüs kısmında bulunan üst eşik üzerine gerilen teller yine sap kısmında bulunan burgular yardımı ile akort edilmektedir.
                        </blockquote>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}