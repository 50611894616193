import React from "react";

import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Footer from '../components/Footer'
import TopNavbar from '../components/Topnavbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

import banner57 from '../assets/img/pagesBanner/banner57.png';
import banner58 from '../assets/img/pagesBanner/banner58.png';
import banner59 from '../assets/img/pagesBanner/banner59.png';
import banner60 from '../assets/img/pagesBanner/banner60.png';

import { Helmet } from "react-helmet";

import { useMediaQuery } from 'react-responsive'
import WhatsAppContact from "../components/WhatsAppContact";

export default function ViolinCourseScreen() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"></meta>
                <meta name="description" content="İstanbul Pendik Kurtköyün en kapsamlı ve en tecrübeli müzik okulu. Black Music Fox Sanat Okulu." />
                <meta name="keywords" content="
                keman dersi,
                keman kursu,
                pendik keman dersi,
                pendik keman kursu,
                kurtköy keman dersi,
                kurtköy keman kursu,
                keman dersleri,
                keman kursu fiyatları,
                keman dersi fiyatları,
                keman eğitimi,
                yetişkinler için keman dersi,
                yetişkinler için keman kursu,
                çocuklar için keman dersi,
                çocuklar için keman kursu,
                keman nasıl öğrenirim,
                istanbul keman dersi,
                istanbul keman kursu,
                anadolu yakası keman kursu,
                anadolu yakası keman dersi,
                "></meta>
                <meta name="author" content="Black Yazılım Ekibi"></meta>
                <title>Black Music Fox | Keman Kursu</title>
            </Helmet>
            <TopNavbar />
                <WhatsAppContact />
            <Container>
                <Row>
                    <Col>
                        <Carousel fade>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner57}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner58}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner59}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src={banner60}
                                    alt="First slide"
                                    style={{ height: 320, width: 1475 }}
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>

                <br></br>
                <br />

                <Row>
                {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h1>Keman Kursumuz</h1>
                        <br></br>
                        <p>Keman Çalmayı</p>
                        <p>En eğlenceli method ile</p>
                        <p>En uygun fiyata</p>
                        <p>Kolay şekilde öğrenmek istiyorsanız</p>

                        <h4 style={{ color: 'red' }}>ÜCRETSİZ DENEME DERSİNE DAVET EDİYORUZ!</h4>

                        <blockquote>
                            Kişiye Özel Ders Programı ile ister Hobi Keman Kursu  Olarak İsterseniz Sınava Hazırlık için  Black Music Fox Okuluna bekliyoruz.
                        </blockquote>

                        <ul>
                            <li>Kursumuzda Keman eğitimi özel ders olarak verilmektedir.</li>
                            <li>Dersler; haftada bir gün 50 dakika  yapılmakta ve öğrenci ile eğitici arasında birebir işlenmektedir.</li>
                            <li>Eğitimler, konusunda kendini ispatlamış hocamız tarafından verilmektedir.</li>
                            <li>Keman Dersleri aynı anda teorik olarak nota-solfej eğitimi ve  Keman ile uygulamalı verilmektedir.</li>
                            <li>Ders günleri ve saatleri öğrencilerle ortak belirlenmektedir.</li>
                            <li>Her öğrencinin seviyesine uygun olarak kemanın tarihçesini, nota ve ritim öğrenimini, Keman çalım tekniklerini aktarmakla başlamaktayız.</li>
                        </ul>
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col md={12}>
                        <h5 style={{ color: 'red' }}>Kemanın Hakkında</h5>
                        <blockquote>
                            Keman, yaylı çalgılar grubuna ait bir müzik enstrümanıdır. Bu enstrümana birçok müzik sever ilgiyle yaklaşmaktadır. Keman hakkında bilgiler bu kişiler tarafından merak konusudur. Keman ahşaptan oluşan gövdesinin üzerinde dört adet tel barındırır. Bu teller farklı notaları temsil eder. Keman arşe ismi verilen bir yay ile çalınır. Bu yay, orijinalinde at kılından yapılmaktadır. Fakat günümüzde farklı materyallerden de üretilmektedir. Keman akort sistemi pes sesten, tiz sese doğru sıralanır. Bu sıralama sol notası, re notası, la notası ve mi notası şeklindedir. Keman sesi birçok farklı müzik türüyle uyumludur. İnsanı derinden etkileyen bir sese sahiptir. Bu nedenle keman yüzyıllardır kullanılmış bir müzik aleti olma özelliğine sahiptir. Kemanın ilk olarak atla yolculuk yapan toplumlarda ortaya çıktığı bilinmektedir.Keman hakkında kısaca bilgi vermek gerekirse yaylı müzik grubunun en küçük üyesidir. Dört tane tele sahiptir. Her tel farklı bir notayı temsil eder. Kemandan ses çıkarmak zorluğuyla bilinir. Keman sesi arşe adı verilen yayın teller üzerinde gezdirilmesi ile çıkarılır. Parmaklar ile notaları çıkararak farklı müzikler oluşturmak mümkündür.
                        </blockquote>
                        <br />
                        <blockquote>
                            Keman çalmak isteyenler kemanın özellikleri nelerdir sorusuyla karşı karşıya kalır. Keman bir gövde ve bir sap bölümünden oluşur. Kemanın üzerinde 4 adet tel bulunur. Bu teller sap bölümünde burguların olduğu yerde son bulur. Kemanın gövde bölümünde göğüs tablası adı verilen üst bölüm ve alt kapak yer alır. Bu alt ve üst kapakları birleştiren bölüme yanlık denir.
                        </blockquote>
                        <br />
                        <blockquote>
                            Keman nasıl çalınır sorusu, keman öğrenmek isteyenlerin merak ettiği bir konudur. Öncelikle yapılması gereken şey kemanı çene ve omuz arasında sabit şekilde tutmaktır. Sol el ile sap bölümünde yer alan tellere basılır ve notalar çıkarılır. Sağ elde ise keman yayı bulunur. Bu yayı teller üzerinde hareket ettirmek suretiyle keman sesi çıkarılır. Kemanın ahşap gövdesindeki yan bölümler içeri doğru girintili biçimdedir. Bunun nedeni keman yayının daha rahat hareket edebilmesidir.
                        </blockquote>
                        <br />
                        <blockquote>
                            Kemanın gövde kısmı genellikle 35 cm uzunluğundan meydana gelir. Keman yapımında genellikle çam ağacı veya ak ağaç kullanılır. Bu ağaç çeşitli ahşap oyma aletleriyle şekillendirilir ve keman ortaya çıkar. Keman yaklaşık olarak 85 adet farklı parçaların birleşmesiyle meydana getirilmiştir. Kemanın sapının en sonunda yer alan burgular telin istenilen oranda gerilmesinde yardımcı olur.
                        </blockquote>
                        <br />
                        <blockquote>
                            Kısa keman hakkında bilgiler vermek gerekirse, yayla çalınan bir telli müzik aletidir. En çok sevilen ve bilinen orkestra müzik aletidir. Kemanın ilk örnekleri Orta Asya’da görülse de, kendine özgü forma Orta Çağ Avrupa’sında kavuşmuştur. Gövde kısmında iki adet delik bulunur. Bu delikler "f” şeklindedir. Kemanın sap kısmı kıvrımlıdır. Keman anahtarlar ile akort edilmektedir. Farklı noktalara basılmasıyla farklı nota ve perdeler oluşur.
                        </blockquote>
                        <br />
                        <blockquote>
                            Keman çalmak için nota yerlerini iyi bilmek gerekir. Bunun için düzenli aralıklarla alıştırmalar yapılır. Kemanın nota ve perde yerlerini bilmek tecrübe ile olur. Bunun için düzenli olarak çalışma yapılmalıdır. Keman, çalması en zorlu enstrümanlardan biridir. Keman çalmayı öğrenmek zaman almaktadır. Bunun için pes etmeden düzenli bir çalışma yürütülmelidir.

                            Keman tellerinin kalınlıkları birbirinden farklıdır. Keman perdesiz müzik aletleri grubundandır. Bu nedenle öğrenme süreci zaman almaktadır. Keman çalmak için arşe adı verilen yay ile teller üzerinde hareket etmek gerekir. Kemandan ses alabilmek için çalma öncesinde reçine adı verilen bir madde ile teller cilalanır. Cilalama işlemi kemandan verimli ses alabilmek için çok önemlidir.

                        </blockquote>
                    </Col>
                </Row>

                <br></br>
                <br />

            </Container>

            <br></br>
            <br />

            <Footer></Footer>
        </div>
    );
}