import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavDropdown from 'react-bootstrap/NavDropdown';

import logo from '../assets/img/blackmusiclogo.png';

function TopNavbar() {
  return (
    <div>
      <Navbar style={{ backgroundColor: 'black' }} collapseOnSelect expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <Row>
              <Col>
                <img
                  alt=""
                  src={logo}
                  width="300"
                  height="75"
                  className="d-inline-block align-top"
                /></Col>
            </Row>
            <Row>
              <Col>
                <h5 style={{ textAlign: 'center' }}>Black Music Fox Enstrüman Hastanesi</h5>
              </Col>
            </Row>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link>
                <Row style={{ paddingRight: 100 }}>
                  <Col>
                    <img src="https://img.icons8.com/ios-glyphs/30/ffa500/clock--v1.png" alt='' />
                    &nbsp; <span style={{ fontSize: 20 }} className='navbar-text justify-content-center'>Çalışma Saatleri:</span> <br />
                    <a style={{ textDecoration: 'none' }} href="/"><span className='navbar-text justify-content-center'>Pzt - Pazar: 10.00 - 21.00</span></a>
                  </Col>
                </Row>
              </Nav.Link>
              <Nav.Link>
                <Row style={{ paddingRight: 100 }}>
                  <Col>
                    <img src="https://img.icons8.com/ios-glyphs/30/ffa500/phone-disconnected.png" alt='' />
                    &nbsp; <span style={{ fontSize: 20 }} className='navbar-text justify-content-center'>Ara:</span> <br />
                    <a style={{ textDecoration: 'none' }} href="tel:553-604-77-57"><span className='navbar-text justify-content-center'>+90 553 604 77 57</span></a>
                  </Col>
                </Row>
              </Nav.Link>
              <Row>
                <Col>
                  <img src="https://img.icons8.com/ios-glyphs/30/ffa500/address.png" alt='' />
                  &nbsp; <span style={{ fontSize: 20 }} className='navbar-text justify-content-center'>Adres:</span> <br />
                  <a style={{ textDecoration: 'none' }} href="https://g.page/Blackmusickurtkoy?share"><span className='navbar-text justify-content-center'>Pendik Yenişehir İST.</span></a>
                </Col>
              </Row>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar collapseOnSelect expand="lg"  bg="light" variant="light">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Anasayfa</Nav.Link>
              <NavDropdown title="Enstrüman Tamiri" id="basic-nav-dropdown">
                <NavDropdown.Item href="/telli-calgilar">Telli Çalgılar</NavDropdown.Item>
                <NavDropdown.Item href="/uflemeli-calgilar">Üflemeli Çalgılar</NavDropdown.Item>
                <NavDropdown.Item href="/vurmali-calgilar">Vurmalı Çalgılar</NavDropdown.Item>
                <NavDropdown.Item href="/yayli-calgilar">Yaylı Çalgılar</NavDropdown.Item>
                <NavDropdown.Item href="/tuslu-calgilar">Tuşlu Çalgılar</NavDropdown.Item>
                <NavDropdown.Item href="/amfi-tamiri">Amfiler</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/kurslar">Kurslar</Nav.Link>
              <Nav.Link href="/hakkimizda">Hakkımızda</Nav.Link>
              <Nav.Link href="/iletisim">İletişim</Nav.Link>
            </Nav>
            <Nav>
              <div>  <a href="https://www.facebook.com/pages/category/Art/Black-Music-Kurtk%C3%B6y-214068726184912/"><img alt='facebook' src="https://img.icons8.com/color/36/000000/facebook-circled--v1.png" /></a> <a href="https://twitter.com/BlackMu16865843/"><img alt='twitter' src="https://img.icons8.com/color/36/000000/twitter-circled--v1.png" /></a> <a href="https://www.instagram.com/blackmusickurtkoy/"><img alt='instagram' src="https://img.icons8.com/color/36/000000/instagram-new--v1.png" /></a> <a href="https://www.youtube.com/channel/UCLiyYBeBbDcFPZqr825Z1hA"><img alt="youtube" src="https://img.icons8.com/color/36/000000/youtube-play.png" /></a> </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default TopNavbar;