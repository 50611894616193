function Footer() {
    return (
        <div>
            <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Kullanışlı Linkler</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i>
                                        <a href="/">Hakkımızda</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <a href="/">Hizmetlerimiz</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <a href="/">Kurslar</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <a href="/">Repertuarım</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <a href="/">İletişim</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Hizmetlerimiz</h4>
                                <ul>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <a href="https://www.blackmusicshop.com/">Enstrüman Satış</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right"></i>
                                        <a href="/">Enstrüman Tamir ve Bakım</a>
                                    </li>
                                    <li>
                                        <i class="bx bx-chevron-right">
                                        </i>
                                        <a href="https://www.blackmusic.com.tr/">Enstrüman Kursları</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-contact">
                                <h4>Bize Ulaşın</h4>
                                <p> Yenişehir Mahallesi, Mustafa Akyol Sokağı
                                    <br /> Grup Center İş merkezi Daire:144
                                    <br /> 34912 Pendik/İstanbul
                                    <br />
                                    <br />
                                    <strong>Telefon:</strong> 0553 604 77 57
                                    <br />
                                    <strong>Email:</strong> blackmusic.ca@gmail.com<br />
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-info">
                                <h3>Black Music Fox</h3>
                                <p>BLACK MUSİC , her yaştan ve her seviyeden öğrencimizin sanat eğitimi alması amacıyla hizmetinize sunmuş bulunmaktayız. BLACK MUSİC kendi müziğinize konsantre olabileceğiniz ve kendinizi geliştirebileceğiniz bir yapıdır. Müzik aleti... <a style={{ color: 'white' }} href='/'>devamı</a></p>
                                <div class="social-links mt-3">
                                    <a href="https://www.facebook.com/pages/category/Art/Black-Music-Kurtk%C3%B6y-214068726184912/"> <i class="fa fa-facebook-f"></i> </a> <a href="https://twitter.com/BlackMu16865843/"> <i class="fa fa-twitter"></i> </a> <a href="https://www.instagram.com/blackmusickurtkoy/"> <i class="fa fa-instagram"></i> </a> <a href="https://www.youtube.com/channel/UCLiyYBeBbDcFPZqr825Z1hA"> <i class="fa fa-youtube"></i> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="copyright">
                        &copy; Copyright <strong>
                            <span>Black Music</span>
                        </strong>. Tüm Hakları Saklıdır.
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;