import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner9 from '../assets/img/pagesBanner/banner9.png'
import banner72 from '../assets/img/pagesBanner/banner72.png'
import banner82 from '../assets/img/pagesBanner/banner82.png'
import banner83 from '../assets/img/pagesBanner/banner83.png'

export const SaksafonFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner9}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner72}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner82}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner83}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Saksafon Tamiri</h2>
                        <h4>Saksafon Nedir ?</h4>
                        <blockquote>
                            Saksofon veya saksafon, çoğunlukla koni ve “S” biçiminde pirinçten üretilen, ağzındaki kamış vasıtasıyla ses çıkaran bir çalgıdır. 1840’li yıllarda, Adolphe Sax tarafından tasarlanmıştır.
                            <br></br>
                            Saksafon tamiri ve bakımında nereler yapılır?
                            <br></br>
                            En çok kullanılan saksafon türleri ;Alto, Tenor, Bariton saksafonlardır.
                            <br></br>
                            Deveboynu ve gövde olmak üzere iki parçadan ibarettir. Gövde üzerinde bulunan babalar, iğne yaylar, mekanizmalar, mekanizma altında petler zamanla hassasiyetini yitirebilir. Babalar lehim yerlerinden kopma sorunu meydana gelir. Güderiler deforme olur. Mantar ve keçe ayarları bozulur. Deveboynu mantarı özelliğini yitirir. Gövde bağlantı lehimi yerinden oynar. Mekanizma kırılabilir. Bütün güderiler yenisiyle değiştirilir. Mantar ve keçe ayarları yapılır. Deveboynu mantarının yerine yenisi sarılır. Kopan lehim yerleri uygun şekilde yapılır. Kırılan mekanizma uygun şekilde gümüş kaynak yapılır. Genel bakımı yapıldıktan sonra son ayarları yapılır. Ayarların bitimiyle gerekli kontroller yapılarak sizlere güvence ile teslim edilir.
                            Müzik aletleri, saksafon tamiri ve bakımı için bize ulaşın.
                        </blockquote>
                    </Col>
                </Row>
                &nbsp;

                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
