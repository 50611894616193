import React from "react";
import { Helmet } from "react-helmet";

import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import HomeScreen from "../src/screens/HomeScreen"
import { ClassicGuitarFixScreen } from "./screens/ClassicGuitarFixScreen";
import { StringedInstrumentsScreen } from "./screens/StringedInstrumentsScreen";
import { WindInstrumentsScreen } from "./screens/WindInstrumentsScreen";
import { PercussionInstrumentsScreen } from "./screens/PercussionInstrumentsScreen";
import { StringInstrumentsScreen } from "./screens/StringInstrumentsScreen";
import { KeyedInstrumentsScreen } from "./screens/KeyedInstrumentsScreen";
import InstrumentCoursesScreen from "./screens/InstrumentCoursesScreen";
import AcusticGuitarCourseScreen from "./screens/AcusticGuitarCourseScreen";
import BaglamaCourseScreen from "./screens/BaglamaCourseScreen";
import ClassicGuitarCourseScreen from "./screens/ClassicGuitarCourseScreen";
import ConservatoryCourseScreen from "./screens/ConservatoryCourseScreen";
import ElectroGuitarCourseScreen from "./screens/ElectroGuitarCourseScreen";
import KabakKemaneCourseScreen from "./screens/KabakKemaneCourseScreen";
import PianoCourseScreen from "./screens/PianoCourseScreen";
import SingingCourseScreen from "./screens/SingingCourseScreen";
import ViolinCourseScreen from "./screens/ViolinCourseScreen";
import AboutUsScreen from "./screens/AboutUsScreen";
import { ContactScreen } from "./screens/ContactScreen";
import { ElectroGuitarFixScreen } from "./screens/ElectroGuitarFixScreen";
import {BassGuitarFixScreen} from "./screens/BassGuitarFixScreen";
import { UdFixScreen } from "./screens/UdFixScreen";
import { BaglamaFixScreen } from "./screens/BaglamaFixScreen";
import { KlarnetFixScreen } from "./screens/KlarnetFixScreen"; // berkay
import { YanFlutFixScreen } from "./screens/YanFlutFixScreen";
import { SaksafonFixScreen } from "./screens/SaksafonFixScreen";
import { DarbukaFixScreen } from "./screens/DarbukaFixScreen";
import { BateriFixScreen } from "./screens/BateriFixScreen";
import { KemanFixScreen } from "./screens/KemanFixScreen";
import { CelloFixScreen } from "./screens/CelloFixScreen";
import { KemenceFixScreen } from "./screens/KemenceFixScreen";
import { KontrobasFixScreen } from "./screens/KontrobasFixScreen";
import { OrgFixScreen } from "./screens/OrgFixScreen";
import { PiyanoFixScreen } from "./screens/PiyanoFixScreen";
import { AmfiFixScreen } from "./screens/AmfiFixScreen";
import { AcusticGuitarFixScreen } from "./screens/AcusticGuitarFixScreen";
import { KabakKemaneFixScreen } from "./screens/KabakKemaneFixScreen";
export default function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />

        <Route path="/telli-calgilar" element={<StringedInstrumentsScreen />} />
        <Route path="/uflemeli-calgilar" element={<WindInstrumentsScreen />} />
        <Route path="/vurmali-calgilar" element={<PercussionInstrumentsScreen />} />
        <Route path="/yayli-calgilar" element={<StringInstrumentsScreen />} />
        <Route path="/tuslu-calgilar" element={<KeyedInstrumentsScreen />} />

        <Route path="/kurslar" element={<InstrumentCoursesScreen />} />
        <Route path="/klasik-gitar-kursu" element={<ClassicGuitarCourseScreen />} />
        <Route path="/akustik-gitar-kursu" element={<AcusticGuitarCourseScreen />} />
        <Route path="/elektro-gitar-kursu" element={<ElectroGuitarCourseScreen />} />
        <Route path="/baglama-kursu" element={<BaglamaCourseScreen />} />
        <Route path="/konservatuara-ve-guzel-sanatlara-hazirlik" element={<ConservatoryCourseScreen />} />
        <Route path="/kabak-kemane-kursu" element={<KabakKemaneCourseScreen />} />
        <Route path="/piyano-kursu" element={<PianoCourseScreen />} />
        <Route path="/san-egitimi-ve-vokal-koclugu" element={<SingingCourseScreen />} />
        <Route path="/keman-kursu" element={<ViolinCourseScreen />} />

        <Route path="/hakkimizda" element={<AboutUsScreen />} />
        <Route path="/iletisim" element={<ContactScreen />} />

        <Route path="/klasik-gitar-tamiri" element={<ClassicGuitarFixScreen />} />
        <Route path="/akustik-gitar-tamiri" element={<AcusticGuitarFixScreen />} />
        <Route path="/elektro-gitar-tamiri" element={<ElectroGuitarFixScreen />} />
        <Route path="/bas-gitar-tamiri" element={<BassGuitarFixScreen />} />
        <Route path="/ud-tamiri" element={<UdFixScreen />} />
        <Route path="/baglama-tamiri" element={<BaglamaFixScreen />} />
        <Route path="/klarnet-tamiri" element={<KlarnetFixScreen />} />
        <Route path="/yan-flut-tamiri" element={<YanFlutFixScreen />} />
        <Route path="/saksafon-tamiri" element={<SaksafonFixScreen />} />
        <Route path="/darbuka-tamiri" element={<DarbukaFixScreen />} />
        <Route path="/bateri-tamiri" element={<BateriFixScreen />} />
        <Route path="/keman-tamiri" element={<KemanFixScreen />} />
        <Route path="/cello-tamiri" element={<CelloFixScreen />} />
        <Route path="/kemence-tamiri" element={<KemenceFixScreen />} />
        <Route path="/kontrobas-tamiri" element={<KontrobasFixScreen />} />
        <Route path="/kabak-kemane-tamiri" element={<KabakKemaneFixScreen />} />
        <Route path="/org-tamiri" element={<OrgFixScreen />} />
        <Route path="/piyano-tamiri" element={<PiyanoFixScreen />} />
        <Route path="/amfi-tamiri" element={<AmfiFixScreen />} />
      </Routes>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
    </BrowserRouter>


  );
}

