import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner2 from '../assets/img/pagesBanner/banner2.png'
import banner5 from '../assets/img/pagesBanner/banner5.png'
import banner10 from '../assets/img/pagesBanner/banner10.png'
import banner70 from '../assets/img/pagesBanner/banner70.png'
import banner75 from '../assets/img/pagesBanner/banner75.png'

export const ClassicGuitarFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner5}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner10}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner70}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner75}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Klasik Gitar Tamiri</h2>
                        <blockquote>
                            Klasik, akustik, elektro ve bass gitarlarınızın her türlü kırık, çatlak, sap ayarı, klavye tesviye, perde ve burgu değişimi usta eller tarafından yapılmaktadır. Her türlü yaylı, mızraplı, telli, vurmalı ahşap müzik aletlerinin tamir ve bakımı garantili bir şekilde yapılır
                        </blockquote>
                        <Row>
                            <Col md={6}>
                                <ul>
                                    <li>Sınırlamalar</li>
                                    <li>Tam Kurulumlar</li>
                                    <li>Kafes Çubuğu Ayarı</li>
                                    <li>Tonlama</li>
                                    <li>Kablo Tesisatı Onarımı</li>
                                    <li>Bileşen Değişimi</li>
                                    <li>Pikap Kurulumu</li>
                                    <li>Özel Kablolama</li>
                                    <li>Somun Değişimi</li>
                                    <li>Eyer Değişimi</li>
                                    <li>El Oyması veya Prefabrik</li>
                                    <li>Pürüzsüz Keskin Perde Uçları</li>
                                </ul>
                            </Col>
                            <Col md={6}>
                                <ul>
                                    <li>Kemik, Tusq, Grafit vb</li>
                                    <li>Fener mili</li>
                                    <li>Çatlak Onarımı</li>
                                    <li>Korse Onarımı</li>
                                    <li>Köprü kaldırma</li>
                                    <li>Kayış kilitleri ve Düğmeler</li>
                                    <li>Akort Makinaları</li>
                                    <li>Kazma korumaları</li>
                                    <li>Donanım Özelleştirme</li>
                                    <li>Tam Refret</li>
                                    <li>Kısmi Refret</li>
                                    <li>Perde Seviyesi, Taç ve Cila</li>
                                </ul>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                &nbsp;

                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
