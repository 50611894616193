import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner14 from '../assets/img/pagesBanner/banner14.png'
import banner19 from '../assets/img/pagesBanner/banner19.png'
import banner68 from '../assets/img/pagesBanner/banner68.png'

export const CelloFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner14}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner19}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner68}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Çello Tamiri</h2>
                        <blockquote>
                            Çello ( Viyolonsel )  Yaylı çalgılar ailesindendir. Yay ile çalınır ve  dört tellidir.
                            <br></br>
                            Çellonun  Fiziki Özellikleri
                            <br></br>
                            . Kavis yapımı: Sırt tahtası dışa doğru hafif bombelidir. Bel kısmı oyuktur.
                            <br></br>
                            . Kulaklar: Abanoz veya tahtadan yapılır.
                            <br></br>
                            . Teller: Metal alaşımlı teller kullanılır.
                            <br></br>
                            . Ses delikleri: Ses rezonansının gövde içinde döndükten sonra dışarıya çıktığı “f”
                            şeklindeki delikler.
                            <br></br>
                            . Gövdeler: Yukardan başlayan yuvarlak hat, köşelere yaklaşırken keskinleşir.
                            Bel kısmı girintilidir.
                            <br></br>
                            . Tuşe: Abanozdan yapılır. Düz ve perdesizdir.
                            <br></br>
                            . Köprüler: üs kısmı bombelidir.
                            <br></br>
                            . Kafa kısımları: Salyangoz kabuğuna benzediği için salyangoz ismini almıştır.
                            <br></br>
                            . Sırt kısmı: Genelde iki tahta parçasının birleştirilmesiyle oluşur. Kemanda da olduğu gibi Tek parça yani Yekpare olarak ‘ta                      yapılabilir.
                            <br></br>
                            . Tel taşıyıcıları: Abanozdan yapılır. Akort için tellerin takıldığı yere fiks denilen
                            bir malzeme takılabilir.
                            <br></br>
                        </blockquote>
                    </Col>
                </Row>
                <br></br>
                <h4>Çello Bakımı</h4>
                <blockquote>
                    Enstrüman direk güneş ışığı ve nemden korunmalıdır. Günlük çalımdan sonra; reçine tozları pamuklu bir bez yardımıyla silinmelidir. Çellonun bütün ahşap bölümüne pamuklu bir bez yardımıyla badem yağı sürülebilir. Badem yağı enstrümanın toz tutmasını engelleyecektir.
                    Çello üzerindeki kırık ve çatlakların onarımı, Çello klavye düzeltme, sap inceltme, burgu değişimi, tel değişimi, köprü ayarlanması, can direği takma, cila ve boya işleri  yapılmaktadır.
                </blockquote>
                <br></br>
                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
