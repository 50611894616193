import React from 'react'

import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useMediaQuery } from 'react-responsive'

import TopNavbar from '../components/Topnavbar'
import Footer from '../components/Footer';
import WhatsAppContact from '../components/WhatsAppContact';
import GoogleMaps from '../components/GoogleMaps';
import { Contact } from '../components/Contact';

import banner1 from '../assets/img/pagesBanner/banner1.png'
import banner41 from '../assets/img/pagesBanner/banner41.png'
import banner42 from '../assets/img/pagesBanner/banner42.png'
import banner87 from '../assets/img/pagesBanner/banner87.png'

export const BaglamaFixScreen = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return (
        <>
            <TopNavbar />
            <WhatsAppContact />
            <br></br>
            <Container>
                <Carousel fade>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner41}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner42}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner87}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <br></br>
                <Row>
                    {isDesktopOrLaptop &&
                        <Col md={7}>
                            <iframe
                                width="550"
                                height="500"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }

                    {isTabletOrMobile &&
                        <Col className="center-block" md={7}>
                            <iframe
                                width="340"
                                height="250"
                                src="https://www.youtube.com/embed/1NjfkGnSLRc"
                                title="Black Music Tanıtım"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscree
                            >
                            </iframe>
                        </Col>
                    }
                    <Col md={5}>
                        <h2>Bağlama Tamiri</h2>
                        <blockquote>
                            Bağlama, ya da saz Türk Halk Müziğinde yaygın olarak kullanılan telli tezeneli bir çalgı türüdür. Yörelere ve boyutlarına göre kopuz, cura, saz, çöğür, dombra, ikitelli, tambura, tar gibi değişik isimlerle tanınır.
                            Kullanılan tekniğe göre mızrap veya parmaklar ile çalınır. Parmaklarla çalma tekniğine şelpe ve dövme denir.
                            Genellikle altta iki çelik ile bir sırma bam, ortada iki çelik ve üstte bir çelik ile bir sırma bam teli olmak üzere toplam 7 tellidir. Tezene ile çalınır.

                        </blockquote>
                        <br></br>
                        <blockquote>
                            Bağlamalarınızın her türlü kırık, çatlak, sap atığı, klavye tesviye, Akort burgu sorunları, Tel değişimi, Eşik değişimi,kapak değişimi,perde değişimi usta eller tarafından yapılmaktadır. Bağlamalarını sahnede kullanmak isteyen müzisyenler için klasik bağlamalara da elektronik donanım yapılmaktadır. Ayrıca kasasız ve feedbacksiz el yapımı mükemmel ses kalitesine sahip elektro bağlamalara sahip olmak isteyen müzisyenlere kendi istedikleri model ve renkte yapılmaktadır.
                        </blockquote>
                    </Col>
                </Row>
                &nbsp;

                <GoogleMaps />
                <Contact />
            </Container>
            <br></br>
            <Footer />
        </>
    )
}
